import axios from 'axios/index';

/**
 * @param {Object} param - The data for storing in user action table.
 * @param {string} param.isNew - If user is new
 * @param {string} param.subscriptionPeriod - Witch type of subscription user pick (monthly/yearly).
 * @param {number} param.seenBunner - How many times user see banner.
 * @param {string} param.lastAction - What user did [
    'continue_for_free',
    'subscribe_now',
    'subscribe_later',
    'subscribe_from_reminder',
    'confirm_pre_order',
    'stay_on_basic_plan',
    'close_popup_reminder',
    'close_popup_subscribe',
    'close_or_back',
    'seen_popup_reminder',
    'seen_billing_page',
    'seen_subscribe_page',
    'seen_subscribe_popup',
    'from_email',
    'back_from_billing_page
].
 */

export default function saveUserAction({
  subscriptionPeriod,
  seenBunner,
  lastAction,
}) {
  axios
    .post('/subscriptions_actions/create_or_update', {
      subscriptionPeriod,
      seenBunner,
      lastAction,
    })
    .then(({ data }) => {
      console.log({ data });
    })
    .catch(err => console.error(err));
}
