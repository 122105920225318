import React from 'react';
import { Modal } from 'react-bootstrap';
import i18n from '../i18n';
import SubscriptionPlans from '../SubscriptionPlans';
import CustomLink from '../CustomLink';
import './SubscriptionPopUp.scss';
import { subscriptionTestingFinished } from '../helpers';
import { SUBSCRIPTION_BILLING } from '../../../constants';

const subscriptionCardsData = [
  {
    title: 'Premium Yearly',
    price: '$3',
    currency: 'USD',
    priceDescription: 'month, paid annually',
  },
  {
    title: 'Premium Monthly',
    price: '$5',
    currency: 'USD',
    priceDescription: 'month, paid monthly',
  },
];

const SubscriptionPopUp = props => {
  const renderSubscriptionCard = subscriptionData => (
    <div className="subs-pop__card" key={subscriptionData.title}>
      <div className="subs-pop__card-top">
        <h3 className="subs-pop__card-title">
          {i18n.t(subscriptionData.title)}
        </h3>
        {!!subscriptionData.label && (
          <span className="subs-pop__card-label">
            {i18n.t(subscriptionData.label)}
          </span>
        )}
      </div>
      <div className="subs-pop__card-price">
        <span className="subs-pop__card-price-value">
          {subscriptionData.price}
        </span>
        <span className="subs-pop__card-price-currency">
          &nbsp;{subscriptionData.currency}
        </span>
        <span className="subs-pop__card-price-description">
          &nbsp;/&nbsp;{i18n.t(subscriptionData.priceDescription)}
        </span>
      </div>
    </div>
  );

  const handleSubscribe = () => {
    props.handleClose({ lastAction: 'subscribe_now' });
    window.location.href = SUBSCRIPTION_BILLING;
  };

  return (
    <Modal
      show={props.show && subscriptionTestingFinished()}
      dialogClassName="subs-pop"
      onHide={() => props.handleClose({ lastAction: 'close_popup_subscribe' })}
    >
      <div className="subs-pop__wrapper">
        <button
          className="subs-pop__close-btn"
          type="button"
          onClick={() =>
            props.handleClose({ lastAction: 'close_popup_subscribe' })
          }
        ></button>
        <h2 className="subs-pop__title">
          {i18n.t('Get more with')}&nbsp;
          <span className="subs-pop__title-product">Waitron Premium</span>
        </h2>
        <p className="subs-pop__sub-title">
          {i18n.t(
            'From March 2023 Waitron Menu will shift to a subscription pricing model.'
          )}
          &nbsp;<b>{i18n.t('Please choose the plan that suits you best')}:</b>
        </p>
        <div className="subs-pop__main">
          <SubscriptionPlans className="subs-pop__main-plans" />
          <div className="subs-pop__main-subscription">
            <div className="subs-pop__main-cards">
              {subscriptionCardsData.map(data => renderSubscriptionCard(data))}
            </div>
            <div className="subs-pop__main-subscription-links">
              <CustomLink
                to="/"
                onClick={() => handleSubscribe()}
                label={i18n.t('Subscribe now with 50% off')}
                className="subs-pop__main-subscription-btn"
                isFullWidth
              />
              <CustomLink
                to="/templates"
                onClick={() =>
                  props.handleClose({ lastAction: 'subscribe_later' })
                }
                label={i18n.t('Subscribe later')}
                isBordered
                className="subs-pop__main-subscription-btn"
                isFullWidth
              />
              <CustomLink
                to="/"
                onClick={() =>
                  props.handleClose({ lastAction: 'stay_on_basic_plan' })
                }
                label={`${i18n.t('Stay on basic account')} →`}
                isFlat
                className="subs-pop__main-subscription-btn"
                isFullWidth
              />
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default SubscriptionPopUp;
