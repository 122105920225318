import React, { Suspense } from 'react';
import PropTypes from 'prop-types';
import { Outlet } from 'react-router-dom';
import Header from '../components/dashboard/header/Header';
import Disclaimer from '../components/dashboard/disclaimer';

const MainLayout = ({ showBannerToVerify, user }) => (
  <>
    <Suspense
      fallback={
        <nav
          style={{
            height: '50px',
            background: '#fff',
          }}
        />
      }
    >
      <Header user={user} />
      {showBannerToVerify && <Disclaimer email={user.email} />}
    </Suspense>

    <main>
      <div className="main-container">
        <Outlet />
        {/* use outlet to render child elements  */}
      </div>
    </main>
  </>
);

MainLayout.propTypes = {
  showBannerToVerify: PropTypes.bool,
  user: PropTypes.object,
};

export default MainLayout;
