import React from 'react';
import PropTypes from 'prop-types';
import BusinessDetailsPopUp from '../../BusinessDetailsPopUp';
import SubscriptionPopUp from '../../SubscriptionPopUp';
import ReminderPopup from '../../reminder_popups/ReminderPopup';
import { SUBSCRIPTION } from '../../../../constants';

const PopUpsContainer = ({ show, typePopup, actions }) => {
  const isSubscriptionPage = window.location.pathname.includes(SUBSCRIPTION);

  return (
    <>
      <BusinessDetailsPopUp
        show={show && typePopup === 'business'}
        handleClose={actions.onCloseBusinessPopup}
      />

      <ReminderPopup
        show={!isSubscriptionPage && show && typePopup === 'reminder'}
        handleClose={actions.handlePopUpClose}
      />

      <SubscriptionPopUp
        show={!isSubscriptionPage && show && typePopup === 'subscribe'}
        handleClose={actions.handlePopUpClose}
      />
    </>
  );
};

PopUpsContainer.propTypes = {
  show: PropTypes.bool,
  typePopup: PropTypes.string,
  actions: PropTypes.shape({
    onCloseBusinessPopup: PropTypes.func,
    handlePopUpClose: PropTypes.func,
  }),
};

export default PopUpsContainer;
