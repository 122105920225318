export default class Image {
  constructor(name, contentType, dataURL) {
    this.name = name;
    this.contentType = contentType;
    this.dataURL = dataURL;
  }

  dataURItoBlob(dataURI) {
    const byteString = atob(dataURI);
    const ab = new ArrayBuffer(byteString.length);
    const byteArray = new Uint8Array(ab);

    for (let i = 0; i < byteString.length; i += 1) {
      byteArray[i] = byteString.charCodeAt(i);
    }

    return new Blob([ab], { type: this.contentType });
  }

  dataURLToDataURI() {
    return this.dataURL.replace(`data:${this.contentType};base64,`, '');
  }

  getBlobData() {
    const dataURI = this.dataURLToDataURI();
    return URL.createObjectURL(this.dataURItoBlob(dataURI));
  }

  downloadFile() {
    const blobData = this.getBlobData();
    const a = document.createElement('a');
    $(a).attr('href', blobData);
    $(a).attr('target', '_parent');
    $(a).attr('download', this.name);
    document.body.appendChild(a);
    a.click();
    a.parentNode.removeChild(a);
  }
}
