import './menuSharing.scss';
import React, { Component } from 'react';
import { Grid, Row, Col } from 'react-bootstrap';
import PropTypes from 'prop-types';
import Popup from '../../../popup/Popup';
import i18n from '../../../i18n';
import { copyToClipboard } from '../../../helpers';
import * as notification from '../../../../common/notifications';
import ImageFallback from '../../../image_fallback/ImageFallback';

class MenuSharing extends Component {
  constructor(props, context) {
    super(props, context);

    this.toggle = this.toggle.bind(this);
    this.copyInput = this.constructor.copyInput.bind(this);
    this.state = {
      sharedLink: '',
      embedCode: '',
      twitterLink: '',
      facebookLink: '',
      pinterestLink: '',
      vkLink: '',
      mailLink: '',
    };
  }

  toggle() {
    this.props.toggle();
  }

  setSharingData(menuLink) {
    this.setState({
      sharedLink: menuLink,
      embedCode: `<div class='embed-responsive embed-responsive-4by3'><iframe class='embed-responsive-item' referrerpolicy='no-referrer-when-downgrade' src='${menuLink}'></iframe></div>`,
      twitterLink: `https://twitter.com/share?url=${menuLink}`,
      facebookLink: `http://www.facebook.com/sharer.php?u=${menuLink}`,
      pinterestLink: `http://www.pinterest.com/pin/create/button/?url=${menuLink}`,
      vkLink: `http://vkontakte.ru/share.php?url=${menuLink}`,
      mailLink: `mailto:?body=${menuLink}`,
    });
  }

  static copyInput(e) {
    e.currentTarget.select();
    document.execCommand('copy');
    e.preventDefault();
    notification.success(i18n.t('Copied'));
  }

  copyTextToClipboard(text) {
    notification.success(i18n.t('Copied'));
    copyToClipboard(text);
  }

  shareOnSM(country) {
    let mutableShareOption = (
      <a
        title="Share by Email"
        href={this.state.mailLink}
        target="_blank"
        className="sm-shared-link mail-link"
        rel="noreferrer"
      >
        <ImageFallback src="/img/social_media/mail.svg" width="29" />
      </a>
    );
    if (country === 'Russia' || country === 'Russian Federation') {
      mutableShareOption = (
        <a
          href={this.state.vkLink}
          target="_blank"
          className="sm-shared-link vk-link"
          rel="noreferrer"
        >
          <ImageFallback src="/img/social_media/vk.svg" width="29" />
        </a>
      );
    }
    return (
      <div className="sm-links-wrapper">
        <label className="caption">{i18n.t('SM modal share text')}</label>
        <Grid fluid={true}>
          <Row>
            <Col xs={3}>
              <a
                href={this.state.twitterLink}
                target="_blank"
                className="sm-shared-link twitter-link"
                rel="noreferrer"
              >
                <ImageFallback src="/img/social_media/twitter.svg" width="29" />
              </a>
            </Col>
            <Col xs={3}>
              <a
                href={this.state.facebookLink}
                target="_blank"
                className="sm-shared-link facebook-link"
                rel="noreferrer"
              >
                <ImageFallback
                  src="/img/social_media/facebook.svg"
                  width="29"
                />
              </a>
            </Col>
            <Col xs={3}>
              <a
                title="Pin It"
                href={this.state.pinterestLink}
                target="_blank"
                className="sm-shared-link pinterest-link"
                rel="noreferrer"
              >
                <ImageFallback
                  src="/img/social_media/pinterest.svg"
                  width="29"
                />
              </a>
            </Col>
            <Col xs={3}>{mutableShareOption}</Col>
          </Row>
        </Grid>
      </div>
    );
  }

  render() {
    const menuLink = this.props.menu
      ? `${window.location.origin}/menu/${this.props.menu.slug}`
      : null;
    return (
      <Popup
        showModal={this.props.showModal}
        toggle={this.toggle}
        onEnter={() => this.setSharingData(menuLink)}
        confirmButtonTitle="OK"
        title={i18n.t('SM modal title')}
      >
        <div className="shared-link-wrapper">
          <label className="caption">{i18n.t('SM modal copy link')}</label>
          <input
            type="text"
            className="shared-link"
            value={this.state.sharedLink}
            readOnly
            onClick={this.constructor.copyInput}
          />
          <button
            type="button"
            className="icon-btn"
            onClick={() => this.copyTextToClipboard(menuLink)}
          >
            <i className="icon-waitron-copy" />
          </button>
        </div>
        {this.shareOnSM(menuLink, this.props.country)}
        <div className="shared-link-wrapper">
          <label className="caption">{i18n.t('SM modal embed')}</label>
          <input
            type="text"
            className="shared-link"
            value={this.state.embedCode}
            readOnly
            onClick={this.constructor.copyInput}
          />
          <button
            type="button"
            className="icon-btn"
            onClick={() => this.copyTextToClipboard(this.state.embedCode)}
          >
            <i className="icon-waitron-copy" />
          </button>
        </div>
      </Popup>
    );
  }
}

MenuSharing.propTypes = {
  country: PropTypes.string,
  toggle: PropTypes.func,
  showModal: PropTypes.bool,
  menu: PropTypes.shape({
    slug: PropTypes.string,
  }),
};

export default MenuSharing;
