import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import './CustomLink.scss';

const CustomLink = ({
  to,
  label,
  className = '',
  isFullWidth = false,
  isBordered = false,
  isFlat = false,
  onClick,
}) => {
  const getLintClassName = () => {
    let initialClassName = 'custom-link';
    if (className) initialClassName += ` ${className}`;
    if (isFullWidth) initialClassName += ` custom-link--full-width`;
    if (isBordered) initialClassName += ` custom-link--bordered`;
    if (isFlat) initialClassName += ` custom-link--flat`;

    return initialClassName;
  };

  return (
    <Link to={to} className={getLintClassName()} onClick={onClick}>
      {label}
    </Link>
  );
};

const CustomLinkPropTypes = {
  to: PropTypes.string.isRequired,
  label: PropTypes.node.isRequired,
  className: PropTypes.string,
  isFullWidth: PropTypes.bool,
  isBordered: PropTypes.bool,
  isFlat: PropTypes.bool,
  onClick: PropTypes.func,
};

CustomLink.propTypes = CustomLinkPropTypes;

export default CustomLink;
