import React from 'react';
import { Button } from 'react-bootstrap';

const SocialMediaButtons = () => {
  const fb = () =>
    (window.location.href = `${window.location.origin}/auth/facebook`);
  const google = () =>
    (window.location.href = `${window.location.origin}/auth/google`);

  return (
    <div className="space-top-bottom social-media-btn-container">
      <Button variant="secondary" onClick={google}>
        <span className="social-media-logo">
          <img src="/img/editor/google-logo.svg" />
        </span>
        Google
      </Button>
      <Button className="active-btn" variant="primary" onClick={fb}>
        <span className="social-media-logo">
          <img src="/img/editor/facebook-logo.svg" />
        </span>
        Facebook
      </Button>
    </div>
  );
};

export default SocialMediaButtons;
