import i18n from 'i18next';
import cookies from 'js-cookie';
import { initReactI18next } from 'react-i18next';

import EN from '../../../locales/en.json';
import UK from '../../../locales/uk.json';
import RU from '../../../locales/ru.json';
import UKCOUNTRIES from '../../../locales/ukCountries.json';
import UKREGIONS from '../../../locales/ukRegions.json';

i18n.use(initReactI18next).init({
  lng: cookies.get('lang'),
  resources: {
    en: {
      translations: EN,
    },
    uk: {
      translations: Object.assign(UK, UKCOUNTRIES, UKREGIONS),
    },
    ru: {
      translations: RU,
    },
  },
  preload: ['en', 'uk', 'ru'],
  fallbackLng: 'en',
  debug: false,
  keySeparator: false,
  ns: ['translations'],
  defaultNS: 'translations',
  interpolation: {
    escapeValue: false,
    formatSeparator: ',',
  },
});

export default i18n;
