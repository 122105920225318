import React from 'react';
import PropTypes from 'prop-types';
import {
  Nav,
  Navbar,
  NavItem,
  Dropdown,
  MenuItem,
  Image,
} from 'react-bootstrap';
import cookies from 'react-cookies';
import i18n from '../../i18n';
import ImageFallback from '../../image_fallback/ImageFallback';
import { DASHBOARD } from '../../../../constants';

import './header.scss';

function Header(props) {
  const authorize = () => (
    <NavItem eventKey={0} href="/sign_in" className="signup-btn">
      {i18n.t('Sign in')}
    </NavItem>
  );

  const print = () => (
    <NavItem eventKey={1} href="/print" className="print">
      <div className="new-print-btn inner-btn btn-white">{i18n.t('Print')}</div>
    </NavItem>
  );

  const userInfo = () => {
    const { name, photo, country } = props.user;
    const printComponent = country === 'Ukraine' ? print() : null;

    return (
      <>
        {printComponent}
        <Dropdown id="user-info" componentClass="li">
          <Dropdown.Toggle componentClass="a">
            <Image src={photo} width="30px" circle />
            {name}
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <MenuItem href="/profile" className="user-option">
              {i18n.t('Account settings')}
            </MenuItem>
            <MenuItem href="/order_history" className="user-option">
              {i18n.t('My orders')}
            </MenuItem>
            <MenuItem
              href="/logout"
              className="user-option"
              onClick={() => cookies.remove('seenBanner')}
            >
              {i18n.t('Log out')}
            </MenuItem>
          </Dropdown.Menu>
        </Dropdown>
      </>
    );
  };

  return (
    <Navbar inverse collapseOnSelect>
      <Navbar.Header>
        <Navbar.Brand>
          <a href={DASHBOARD}>
            <ImageFallback
              src="/img/editor/logo-title.svg"
              alt="Waitron.Menu"
              className="logo-img"
              height="30"
            />
          </a>
        </Navbar.Brand>
        <Navbar.Toggle />
      </Navbar.Header>
      <Navbar.Collapse>
        <Nav pullRight>{props.user ? userInfo() : authorize()}</Nav>
      </Navbar.Collapse>
    </Navbar>
  );
}

Header.propTypes = {
  user: PropTypes.shape({
    name: PropTypes.string,
    photo: PropTypes.string,
    country: PropTypes.string,
  }),
};

export default Header;
