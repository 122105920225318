import React, { Component } from 'react';
import PropTypes from 'prop-types';
import withFormats from './withFormats';
import i18n from '../../i18n';

class FormatsDropdownMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      toggle: false,
    };
    this.toggleFormats = this.toggleFormats.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  toggleFormats() {
    this.setState({ toggle: !this.state.toggle });
  }

  handleChange(e) {
    this.props.onFormatChange(e.currentTarget.id);
    this.toggleFormats();
  }

  renderFormat(format) {
    return (
      <li key={format.id} className="list-item">
        <a id={format.name} onClick={this.handleChange}>
          {i18n.t(format.name)}
        </a>
      </li>
    );
  }

  render() {
    const formats = this.props.formats;
    const { toggle } = this.state;
    return (
      <div className="dropdown">
        <button
          className="icon-btn dropdown-toggle"
          data-toggle="dropdown"
          onClick={this.toggleFormats}
        >
          <span>{i18n.t(this.props.currentFormat)}</span>
          <i className="icon-waitron-dropdown"></i>
        </button>
        <ul
          id="format-list"
          className="nav dropdown-menu"
          style={{ right: 0, left: 'auto', display: toggle ? 'block' : 'none' }}
        >
          <li>
            <a data-toggle="collapse" data-target="#eu-formats">
              {i18n.t('EU formats')}
            </a>
            <ul id="eu-formats" className="collapse custom-list">
              {formats.euFormats.map(format => this.renderFormat(format))}
            </ul>
          </li>
          <li role="separator" className="divider"></li>
          <li>
            <a data-toggle="collapse" data-target="#us-formats">
              {i18n.t('US formats')}
            </a>
            <ul id="us-formats" className="collapse custom-list">
              {formats.usFormats.map(format => this.renderFormat(format))}
            </ul>
          </li>
        </ul>
      </div>
    );
  }
}

FormatsDropdownMenu.propTypes = {
  currentFormat: PropTypes.string,
  onFormatChange: PropTypes.func,
  formats: PropTypes.shape({
    euFormats: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
      })
    ),
    usFormats: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
      })
    ),
  }),
};

export default withFormats(FormatsDropdownMenu);
