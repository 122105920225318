import whiteList from './whiteListForOldUsers';
import * as notification from '../../common/notifications';
import i18n from '../i18n';

export function copyToClipboard(text) {
  // note: Clipboard API is used
  // because Document.execCommand() is obsolete and doesn't work properly
  // https://developer.mozilla.org/en-US/docs/Web/API/Document/execCommand
  if (navigator && navigator.clipboard) {
    navigator.clipboard
      .writeText(text)
      .then(() => navigator.clipboard.readText());
    notification.success(i18n.t('Copied'));

    return;
  }
  const temp = $('<input>');
  $('body').append(temp);
  temp.val(text).select();
  document.execCommand('copy');
  temp.remove();
}

export function isValidName(name) {
  return /^[a-zA-Z0-9ЁёА-я ()]+$/.test(name);
}

export function toFixedTwo(number) {
  return Number(number.toFixed(2));
}

export const subscriptionTestingFinishDate = new Date('Mar 05, 2023 00:00:00');

export const subscriptionTestingFinished = () => {
  const now = new Date();
  return subscriptionTestingFinishDate.getTime() < now.getTime();
};

export const isShowSubscriptionFlow = user => {
  if (!user && !user.id) return false;

  if (subscriptionTestingFinished()) return false;

  const userId = user.id;
  const userCreateDate = new Date(user.created_at);
  const dateForNewUsers = new Date('Feb 01, 2023 00:00:00');
  const isNew =
    user.is_new || userCreateDate.getTime() > dateForNewUsers.getTime();

  if (isNew || user.test_user) {
    return true;
  }

  if (!whiteList.includes(userId)) {
    return false;
  }

  return false;
};
