fabric.IText.prototype.initHiddenTextarea = (function (initHiddenTextarea) {
    return function () {
        let result = initHiddenTextarea.apply(this);
        if (document.querySelectorAll(".modal.fade.in").length > 0) {
            fabric.document.body.removeChild(this.hiddenTextarea);
            document.getElementsByClassName("modal fade in")[0].appendChild(this.hiddenTextarea);
        }
        return result;
    };
})(fabric.IText.prototype.initHiddenTextarea);
