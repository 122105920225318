import React from 'react';
import i18n from '../i18n';

import './Price.scss';

const Price = ({
  price,
  currency,
  recurringInterval,
  discount,
  label,
  saveAmount,
  type = 'column',
  className = '',
}) => {
  const getClassName = () => {
    let initialClass = `price-block price-block--${type}`;
    if (className) initialClass += ` ${className}`;

    return initialClass;
  };

  return (
    <div className={getClassName()}>
      {discount && (
        <span className="price-block__discount">
          <span>
            <span className="price-block__discount-amount">
              {`$${price}`} {` ${currency} `}
            </span>
            &nbsp;
          </span>
          {label && (
            <span className="price-block__label">
              {`${i18n.t(label)} ${saveAmount}`}
            </span>
          )}
        </span>
      )}
      <div>
        <span className="price-block__amount">
          {`$${discount ? Math.round(price * discount * 100) / 100 : price}`}
          <span className="price-block__currency">{` ${currency} `}</span>
        </span>
        <span className="price-block__interval">
          {`/ ${recurringInterval}`}
        </span>
      </div>
    </div>
  );
};

export default Price;
