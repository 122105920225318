import './templatePreview.scss';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ImageFallback from '../../../image_fallback/ImageFallback';

class TemplatePreview extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const template = this.props.template;
    const designFolder = template.isDesignOld
      ? template.designName
      : template.design_id;
    // todo: check if discount is enabled for a specific user
    const pricePlaceholder = template.isPaidAndNonPurchased ? (
      <div className="price-placeholder">${template.price}</div>
    ) : (
      false
    );
    const preview = pageNumber => (
      <ImageFallback
        alt={template.designName}
        height={319}
        src={`/img/templates/root/${designFolder}/${template.formatName}/preview${pageNumber}.jpeg`}
        width="auto"
      />
    );
    return (
      <div className="template-wrapper">
        <div className="rotate-card">
          <div
            data-templateid={template.id}
            data-template-is-price={!!template.price}
            onClick={this.props.handleOpen}
            className={`format-${template.formatName}`}
          >
            {pricePlaceholder}
            <div className="front-face-template template-face">
              {preview(1)}
            </div>
            <div className="back-face-template template-face">{preview(2)}</div>
          </div>
        </div>
      </div>
    );
  }
}

TemplatePreview.propTypes = {
  user: PropTypes.object,
  template: PropTypes.shape({
    id: PropTypes.number,
    isDesignOld: PropTypes.bool,
    designName: PropTypes.string,
    design_id: PropTypes.number,
    isPaidAndNonPurchased: PropTypes.bool,
    price: PropTypes.number,
    formatName: PropTypes.string,
  }),
};

export default TemplatePreview;
