/* eslint-disable max-classes-per-file */
import React from 'react';
import { Button } from 'react-bootstrap';
import { allCountries } from 'country-region-data';
import queryString from 'query-string';
import { PaymentInputsContainer } from 'react-payment-inputs';
import images from 'react-payment-inputs/images';
import InputFormGroup from '../../components/form_group/InputFormGroup';
import Price from '../../components/Price';
import i18n from '../../components/i18n';
import CustomLink from '../../components/CustomLink';
import saveUserAction from '../../common/saveUserAction';
import saveBilling from '../../common/saveBilling';
import SelectComponent from '../../components/Selects/SelectComponent';
import withRouter from '../../hocs/withRouter';
import { SUBSCRIPTION_SUCCESS } from '../../../constants';

import './SubscriptionBilling.scss';

const countries = allCountries.reduce((a, v) => ({ ...a, [v[1]]: v[0] }), {});

const countryRegions = allCountries.reduce(
  (a, v) => ({ ...a, [v[1]]: v[2] }),
  {}
);

const formatOptions = (items = []) =>
  items
    .map(c => ({
      value: i18n.t(c[0]),
      key: c[1],
    }))
    .sort((a, b) => new Intl.Collator().compare(a.value, b.value)) || [];

const SelectableCard = ({ selected, onClick, children }) => (
  <div
    className={`card ${selected ? 'selected' : 'selectable'}`}
    onClick={onClick}
  >
    {children}
    <div className="check" />
  </div>
);

function cc_format(value) {
  const v = value
    .replace(/\s+/g, '')
    .replace(/[^0-9]/gi, '')
    .substr(0, 16);
  const parts = [];

  for (let i = 0; i < v.length; i += 4) {
    parts.push(v.substr(i, 4));
  }

  return parts.length > 1 ? parts.join(' ') : value;
}

const getIntervalIndex = () => {
  const href = window.location.href;
  const interval = queryString.parse(href.substring(href.indexOf('?') + 1))
    .interval;

  if (interval === 'year') {
    return 0;
  }
  if (interval === 'month') {
    return 1;
  }
  return 0;
};

class SelectableCardList extends React.Component {
  constructor(props) {
    super(props);
    const intervalIndex = getIntervalIndex();

    const selected = intervalIndex;
    const initialState = {
      selected: selected,
    };

    this.state = initialState;
  }

  onItemSelected(index) {
    this.setState((prevState, props) => {
      props.onChange(index);
      return {
        selected: index,
      };
    });
  }

  render() {
    const content = this.props.contents.map((cardContent, i) => {
      const { title, price } = cardContent;

      return (
        <SelectableCard
          key={i}
          selected={this.state.selected === i}
          onClick={() => this.onItemSelected(i)}
        >
          <div className="content">
            <h3 className="text-gradient title">{title}</h3>
            <Price
              price={price.amount}
              currency={price.currency}
              recurringInterval={i18n.t(price.recurringInterval)}
              discount={price.discount}
              type="row"
            />
          </div>
        </SelectableCard>
      );
    });

    return <div className="cardlist">{content}</div>;
  }
}

const discount = 0.5;
const subscriptionPlans = [
  {
    key: 'year',
    title: i18n.t('Premium Yearly'),
    price: {
      amount: 3,
      recurringInterval: i18n.t('month, paid annually'),
      currency: 'usd',
      discount,
    },
  },
  {
    key: 'month',
    title: i18n.t('Premium Monthly'),
    price: {
      amount: 5,
      recurringInterval: i18n.t('month, paid monthly'),
      currency: 'usd',
      discount,
    },
  },
];

class SubscriptionBilling extends React.Component {
  constructor(props) {
    super(props);
    const intervalIndex = getIntervalIndex();
    const city = '';
    const expirationMonth = '';
    const expirationYear = '';
    const cardCvvValue = '';

    this.state = {
      selectedIndex: intervalIndex,
      selectedPlan: subscriptionPlans[intervalIndex],
      email: null,
      firstName: null,
      lastName: null,
      country: null,
      city,
      expirationMonth,
      expirationYear,
      cardCvvValue,
      cardNumber: '',
    };
  }

  onListChanged(selected) {
    this.setState(oldState => ({
      ...oldState,
      selectedIndex: selected,
      selectedPlan: subscriptionPlans[selected],
    }));
  }

  onBillingFormChangeEmail(email) {
    this.setState(oldState => ({
      ...oldState,
      email,
    }));
  }

  onBillingFormChangeFirstName(firstName) {
    this.setState(oldState => ({
      ...oldState,
      firstName,
    }));
  }

  onBillingFormChangeLastName(lastName) {
    this.setState(oldState => ({
      ...oldState,
      lastName,
    }));
  }

  onChangeCardNumber({ target }) {
    this.setState(oldState => ({
      ...oldState,
      cardNumber: target.value,
    }));
  }

  calculateOrder() {
    const plan = subscriptionPlans[this.state.selectedIndex];
    const selectedPlan = {
      key: plan.key === 'year' ? 'year' : 'month',
      title: plan.title,
      amount: plan.key.includes('year')
        ? plan.price.amount * 12
        : plan.price.amount,
    };
    const discountAmount =
      Math.round(selectedPlan.amount * plan.price.discount * 100) / 100;
    return {
      total: selectedPlan.amount - discountAmount,
      discount: Math.round(plan.price.discount * 100),
      discountAmount,
      selectedPlan,
    };
  }

  handleConfirmPreOrder = () => {
    saveUserAction({
      lastAction: 'confirm_pre_order',
      subscriptionPeriod: this.state.selectedPlan.key,
    });
    saveBilling({
      email: this.state.email,
      firstName: this.state.firstName,
      lastName: this.state.lastName,
      country: this.state.country,
    });

    this.props.router.navigate(SUBSCRIPTION_SUCCESS);
  };

  handleBack = () => {
    saveUserAction({
      lastAction: 'back_from_billing_page',
    });
  };

  setCountry = countryCode => {
    this.setState(oldState => ({
      ...oldState,
      countryCode,
      country: countries[countryCode],
    }));
  };

  setRegion = region => {
    this.setState(oldState => ({
      ...oldState,
      region,
    }));
  };

  focusSibling(target, direction) {
    const nextTarget = target[direction];
    if (nextTarget) nextTarget.focus();
  }

  expirationMonthHandler(e) {
    const value = e.target.value.toString();

    if (value.length === 1 && value > 1) {
      e.target.value = `0${value}`;
    }

    if (value === '00') {
      e.target.value = '01';
    } else if (value > 12) {
      e.target.value = '12';
    }

    e.target.value.replace(/[^\d]/gi, '').length >= 2 &&
      this.focusSibling(e.target, 'nextElementSibling');
    this.setState({ expirationMonth: e.target.value.replace(/[^\d]/gi, '') });
    e.stopPropagation();
  }

  expirationMontBurhHandler(e) {
    const value = e.target.value.toString();

    if (value === '0' || value === '1') {
      e.target.value = '01';
    }

    this.setState({ expirationMonth: e.target.value.replace(/[^\d]/gi, '') });
    e.stopPropagation();
  }

  getCurrentYear() {
    return Number(
      new Date()
        .getFullYear()
        .toString()
        .slice(-2)
    );
  }

  expirationYearBlurHandler(e) {
    const value = e.target.value.toString();

    if (value < this.getCurrentYear() && value.length >= 1) {
      e.target.value = this.getCurrentYear();
    }
  }

  expirationYearKeydownHandler(e) {
    const value = e.target.value.toString();

    if (e.key === 'Backspace' && value.length === 0) {
      this.focusSibling(e.target, 'previousElementSibling');
      e.stopPropagation();
    }
  }

  render() {
    const orderData = this.calculateOrder.bind(this)();
    const countryOptions = formatOptions(allCountries);

    const regionsOptions = countryRegions[this.state.countryCode]
      ? formatOptions(countryRegions[this.state.countryCode])
      : [];

    const submitWrapper = this.props.conditionalExecutionWithVerificationBanner(
      e => {
        e.preventDefault();
        this.handleConfirmPreOrder();
      }
    );

    return (
      <div className="subscription-billing subscription-billing__container">
        <CustomLink
          to={'/subscription'}
          onClick={() => this.handleBack()}
          label={
            <span>
              <img
                src="/img/subscription/back-arrow.svg"
                width="24"
                alt="arrow"
              />
              {i18n.t('Back')}
            </span>
          }
          className="back-btn"
          isFlat
        />
        <h1 className="subscription-billing__title">
          {i18n.t('Subscribe to')}{' '}
          <span className="text-gradient">Waitron Premium</span>
        </h1>
        <PaymentInputsContainer>
          {({ meta, getCardNumberProps, getCardImageProps }) => (
            <form className="form-container" onSubmit={submitWrapper}>
              <div className="billing-data">
                <h2 className="form-title">
                  {i18n.t('Complete your subscription with payment info')}
                </h2>

                <div className="subscription-billing__field-wrap subscription-billing__field-wrap--two-columns">
                  <InputFormGroup
                    type="text"
                    caption={i18n.t('First name')}
                    controlId="email-input"
                    onInput={this.onBillingFormChangeFirstName.bind(this)}
                  />
                  <InputFormGroup
                    type="text"
                    caption={i18n.t('Last name')}
                    controlId="email-input"
                    onInput={this.onBillingFormChangeLastName.bind(this)}
                  />
                </div>
                <div className="subscription-billing__field-wrap subscription-billing__field-wrap--two-columns subscription-billing__field-wrap--card">
                  <div
                    className={`subscription-billing__card-wrap ${
                      (meta.isTouched && meta.error) ||
                      (meta.touchedInputs.cardNumber && meta.error)
                        ? 'subscription-billing__card-wrap--error'
                        : ''
                    }`}
                  >
                    <label
                      className="subscription-billing__card-label"
                      htmlFor="card-number"
                    >
                      {i18n.t('Card Number')}
                    </label>
                    <div className="subscription-billing__card-field">
                      <input
                        {...getCardNumberProps({
                          onChange: this.onChangeCardNumber.bind(this),
                        })}
                        className="subscription-billing__card-input"
                        placeholder="0000 0000 0000 0000"
                        id="card-number"
                        value={cc_format(this.state.cardNumber)}
                        maxLength="19"
                        required
                      />
                    </div>
                    <svg
                      className="subscription-billing__card-icon"
                      {...getCardImageProps({ images })}
                    />
                    {((meta.isTouched && meta.error) ||
                      (meta.touchedInputs.cardNumber && meta.error)) && (
                      <span className="subscription-billing__card-error">
                        {i18n.t(meta.error)}
                      </span>
                    )}
                  </div>
                  <div className="subscription-billing__field-wrap-card">
                    <div className="subscription-billing__card-wrap">
                      <label
                        className="subscription-billing__card-label"
                        htmlFor="expiration-month"
                      >
                        {i18n.t('Expiration date')}
                      </label>
                      <div className="subscription-billing__expiration-fields">
                        <input
                          className="subscription-billing__card-input subscription-billing__card-input--exp"
                          type="text"
                          placeholder="MM"
                          name="expiration-month"
                          id="expiration-month"
                          maxLength="2"
                          minLength="2"
                          value={this.state.expirationMonth}
                          required
                          onBlur={this.expirationMontBurhHandler.bind(this)}
                          onInput={this.expirationMonthHandler.bind(this)}
                        />
                        <input
                          className="subscription-billing__card-input subscription-billing__card-input--exp"
                          type="text"
                          placeholder="YY"
                          id="expiration-year"
                          maxLength="2"
                          minLength="2"
                          value={this.state.expirationYear}
                          required
                          // onChange={this.expirationYearhHandler.bind(this)}
                          onKeyDown={this.expirationYearKeydownHandler.bind(
                            this
                          )}
                          onBlur={this.expirationYearBlurHandler.bind(this)}
                          onInput={e => {
                            this.setState({
                              expirationYear: e.target.value.replace(
                                /[^\d]/gi,
                                ''
                              ),
                            });
                          }}
                        />
                      </div>
                    </div>
                    <div className="subscription-billing__csv">
                      <InputFormGroup
                        className="form-input"
                        type="text"
                        placeholder="CVV"
                        maxLength="3"
                        minLength="3"
                        caption={i18n.t('Security code')}
                        controlId="security-input"
                        value={this.state.cardCvvValue}
                        onInput={value => {
                          this.setState({
                            cardCvvValue: value.replace(/[^\d]/gi, ''),
                          });
                        }}
                      />
                    </div>
                  </div>

                  <span className="subscription-billing__field-wrap-hint">
                    <img src="/img/subscription/lock.svg" alt="lock" />
                    <span>{i18n.t('Secure payment with SSL Encryption')}</span>
                  </span>
                </div>
                <div className="subscription-billing__field-wrap">
                  <InputFormGroup
                    className="form-input"
                    type="text"
                    caption={i18n.t('Billing address')}
                    controlId="address-input"
                    onInput={() => console.log('input')}
                  />
                </div>
                <div className="subscription-billing__field-wrap subscription-billing__field-wrap--two-columns">
                  <SelectComponent
                    options={countryOptions}
                    onChange={item => this.setCountry(item)}
                    selectedKey={this.state.countryCode}
                    caption={i18n.t('Country')}
                    onlyLetters
                    controlId="country-input"
                  />
                  <InputFormGroup
                    className="form-input"
                    type="number"
                    caption={i18n.t('ZIP code')}
                    controlId="zip-code-input"
                    onInput={() => console.log('input')}
                  />
                </div>
                <div className="subscription-billing__field-wrap subscription-billing__field-wrap--two-columns">
                  <SelectComponent
                    options={regionsOptions}
                    onChange={item => console.log(item)}
                    selectedKey={this.state.region}
                    caption={i18n.t('State/Region')}
                    onlyLetters
                    controlId="state-input"
                  />
                  <InputFormGroup
                    className="form-input"
                    type="text"
                    caption={i18n.t('City')}
                    controlId="city-input"
                    value={this.state.city}
                    onInput={value => {
                      this.setState({ city: value.replace(/[0-9]/gi, '') });
                    }}
                  />
                </div>
                <div className="subscription-billing__field-wrap subscription-billing__field-wrap--last">
                  <InputFormGroup
                    className="form-input"
                    type="email"
                    caption={i18n.t('Billing email')}
                    controlId="email-input"
                    onInput={this.onBillingFormChangeEmail.bind(this)}
                  />
                </div>
              </div>
              <div className="subscription-plan">
                <h2 className="form-title">
                  {i18n.t('Choose the plan that suits you best')}
                </h2>
                <div className="plan-container">
                  <SelectableCardList
                    contents={subscriptionPlans}
                    onChange={this.onListChanged.bind(this)}
                  />
                </div>
                <div className="order-data-container">
                  <div className="order-data">
                    <span>{i18n.t(orderData.selectedPlan.title)}</span>
                    <span>${orderData.selectedPlan.amount}</span>
                  </div>

                  {orderData.discount && (
                    <div className="order-data discount">
                      <span>
                        {i18n.t('Discount')} {orderData.discount}%
                      </span>
                      <span>-${orderData.discountAmount}</span>
                    </div>
                  )}

                  <div className="order-data total">
                    <span>{i18n.t('Total')}</span>{' '}
                    <span>${orderData.total}</span>
                  </div>
                  <span className="hint">
                    <img src="/img/subscription/info-icon.svg" alt="arrow" />
                    <span>
                      &nbsp;{i18n.t('It`s pre-order, you won`t be charged now')}
                    </span>
                  </span>
                </div>
                <Button
                  className="order-btn"
                  variant="primary"
                  type="submit"
                  disabled={
                    !!(
                      (meta.isTouched && meta.error) ||
                      (meta.touchedInputs.cardNumber && meta.error)
                    )
                  }
                >
                  {i18n.t('Confirm pre-order')}
                </Button>
              </div>
            </form>
          )}
        </PaymentInputsContainer>
      </div>
    );
  }
}

export default withRouter(SubscriptionBilling);
