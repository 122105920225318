import './index.scss';
import React, { Component } from 'react';
import axios from 'axios/index';
import { Button } from 'react-bootstrap';
import PropTypes from 'prop-types';
import cookies from 'react-cookies';
import QRCode from 'qrcode';

import i18n from '../../i18n';
import Popup from '../../popup/Popup';
import InputFormGroup from '../../form_group/InputFormGroup';
import MenuProperties from './menu_properties/MenuProperties';
import MenuSharing from './menu_sharing/MenuSharing';
import MenuQRCode from './menu_qr_code/MenuQRCode';
import FormatPreviewTabs from '../formats/format_preview_tabs/FormatPreviewTabs';
import { isValidName } from '../../helpers/index';
import * as notification from '../../../common/notifications';
import NavigationBar from '../navigation_bar/NavigationBar.jsx';
import ImageFallback from '../../image_fallback/ImageFallback';
import Image from '../../helpers/Image';
import { MENUS } from '../../../../constants';

class Menus extends Component {
  constructor(props) {
    super(props);

    this.toggleMenuParametersModal = this.toggleMenuParametersModal.bind(this);
    this.toggleMenuSharingModal = this.toggleMenuSharingModal.bind(this);
    this.toggleMenuFormatsModal = this.toggleMenuFormatsModal.bind(this);
    this.toggleMenuQRCodeModal = this.toggleMenuQRCodeModal.bind(this);

    this.createFromScratch = this.createFromScratch.bind(this);
    this.createInCustomSize = this.createInCustomSize.bind(this);
    this.markAsMain = this.markAsMain.bind(this);
    this.resize = this.resize.bind(this);
    this.resizeToCustomSize = this.resizeToCustomSize.bind(this);

    this.customSizeChangeWidth = this.customSizeChangeWidth.bind(this);
    this.customSizeChangeHeight = this.customSizeChangeHeight.bind(this);
    this.customSizeChangeUnit = this.customSizeChangeUnit.bind(this);

    this.onFormatSelected = this.onFormatSelected.bind(this);
    this.onNameChanged = this.onNameChanged.bind(this);

    this.setActiveTab = this.setActiveTab.bind(this);
    this.dispatchMenuProperties = this.dispatchMenuProperties.bind(this);

    this.state = {
      menus: [],
      mainMenuId: null,
      activeMenu: null,
      selectedFormatName: 'a4-0f',
      name: i18n.t('Menu name'),
      actions: ['remove', 'share', 'duplicate', 'resize', 'get-qr-code'],
      showMenuParametersModal: false,
      showMenuSharingModal: false,
      showMenuFormatsModal: false,
      error: false,
      customSizeWidth: '',
      customSizeHeight: '',
      customSizeUnit: 'mm',
      activeTab: 1,
    };
  }

  componentDidMount() {
    this.props.user ? this.get() : null;
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.user && this.props.user) {
      return this.get();
    }
  }

  get() {
    axios
      .get('/new_flow/menus')
      .then(({ data }) => {
        if (!data.menus.length) return;
        const mainMenuId = data.menus[0] ? data.menus[0].id : null; // note: the main menu is always the first item of this array
        this.setState({ mainMenuId, menus: data.menus });
      })
      .catch(err => console.error(err));
  }

  toggleMenuParametersModal() {
    this.setState({
      showMenuParametersModal: !this.state.showMenuParametersModal,
    });
  }

  toggleMenuSharingModal(menuId) {
    this.setState({
      showMenuSharingModal: !this.state.showMenuSharingModal,
      activeMenu: this.state.menus.find(menu => menu.id === menuId),
    });
  }

  toggleMenuFormatsModal(menuId) {
    this.setState({
      showMenuFormatsModal: !this.state.showMenuFormatsModal,
      activeMenu: this.state.menus.find(menu => menu.id === menuId),
    });
  }

  toggleMenuQRCodeModal(menuId) {
    this.setState({
      showMenuQRCodeModal: !this.state.showMenuQRCodeModal,
      activeMenu: this.state.menus.find(menu => menu.id === menuId),
    });
  }

  createFromScratch() {
    if (!isValidName(this.state.name) && this.state.error) {
      return notification.error(i18n.t('Incorect menu name'));
    }
    if (this.props.user) {
      $.post('/menu', {
        name: this.state.name,
        format: this.state.selectedFormatName,
      }).then(menu => {
        $(location).attr(
          'href',
          `/app?format=${this.state.selectedFormatName}&menu=${menu.id}&template=&slug=${menu.slug}`
        );
      });
    } else {
      cookies.save(
        'menuParams',
        { name: this.state.name, format: this.state.selectedFormatName },
        { path: '/' }
      );
      $(location).attr('href', `/auth`);
    }
  }

  onFormatSelected(e) {
    this.setState({ selectedFormatName: e.currentTarget.id });
  }

  customSizeChangeWidth(value) {
    this.setState({ customSizeWidth: Number(value) });
  }

  customSizeChangeHeight(value) {
    this.setState({ customSizeHeight: Number(value) });
  }

  customSizeChangeUnit(e) {
    this.setState({ customSizeUnit: e.currentTarget.id });
  }

  setActiveTab(tab) {
    this.setState({ activeTab: tab });
  }

  onNameChanged(name) {
    this.setState({ name });
    !isValidName(name)
      ? this.setState({ error: true })
      : this.setState({ error: false });
  }

  dispatchMenuProperties(property, menuId) {
    switch (property) {
      case 'remove-menu':
        return this.deleteMenu(Number(menuId));
      case 'duplicate-menu':
        return this.duplicate(Number(menuId));
      case 'share-menu':
        return this.toggleMenuSharingModal(Number(menuId));
      case 'resize-menu':
        return this.toggleMenuFormatsModal(Number(menuId));
      case 'get-qr-code-menu':
        return this.toggleMenuQRCodeModal(Number(menuId));
    }
  }

  markAsMain(e) {
    const mainMenuId = Number(
      $(e.currentTarget)
        .parent('.menu-wrapper')
        .attr('data-menuid')
    );
    const self = this;
    $.ajax({
      url: `/menu/${mainMenuId}/main`,
      method: 'PUT',
      success() {
        const mainMenuIndex = self.state.menus.findIndex(
          menu => menu.id === mainMenuId
        );
        const mainMenu = self.state.menus[mainMenuIndex];
        self.state.menus.splice(mainMenuIndex, 1);
        self.state.menus.unshift(mainMenu);
        self.setState({ mainMenuId });
      },
    });
  }

  deleteMenu(id) {
    $.post(`/menu/${id}/delete`, () => {
      const { menus } = this.state;
      const deletedMenuIndex = menus.findIndex(menu => menu.id === id);
      menus.splice(deletedMenuIndex, 1);
      this.setState({ menus });
      notification.success(i18n.t('Deleted'));
    }).catch(() => notification.error(i18n.t('Something went wrong')));
  }

  duplicate(id) {
    $.post(`/menu/${id}/duplicate`, duplicatedMenu => {
      const { menus } = this.state;
      menus.unshift(duplicatedMenu);
      this.setState({ menus });
      notification.success(i18n.t('Duplicated'));
    }).catch(notification.error(i18n.t('Something went wrong')));
  }

  resize() {
    $.post(
      `/menu/${this.state.activeMenu.id}/resize`,
      {
        selectedFormatName: this.state.selectedFormatName,
      },
      ({ menuId, templateId, slug }) => {
        $(location).attr(
          'href',
          `/app?format=${
            this.state.selectedFormatName
          }&menu=${menuId}&template=${templateId || 'false'}&slug=${slug}`
        );
      }
    ).catch(notification.error(i18n.t('Something went wrong')));
  }

  resizeToCustomSize() {
    let { customSizeWidth, customSizeHeight, customSizeUnit } = this.state;
    const MPI = 25.4; // mm, 1 inch = 25.4 mm
    const printExtra = 4; // mm
    const screenPPI = 72;

    // todo: validate it, check ratio
    if (!(customSizeWidth && customSizeHeight)) {
      return notification.error('Incorrect Custom Size Values');
    }

    if (customSizeUnit === 'inch') {
      customSizeWidth = Number((customSizeWidth * MPI).toFixed(1));
      customSizeHeight = Number((customSizeHeight * MPI).toFixed(1));
    }
    axios
      .post('/format', {
        width: Number(
          (screenPPI * (customSizeWidth + printExtra)) / MPI
        ).toFixed(1),
        height: Number(
          (screenPPI * (customSizeHeight + printExtra)) / MPI
        ).toFixed(1),
        printWidth: customSizeWidth + 4,
        folds: this.state.activeMenu.format.folds,
      })
      .then(({ data }) => {
        this.setState({ selectedFormatName: data.format.name });
        this.resize();
      })
      .catch(err => console.error(err));
  }

  createInCustomSize() {
    let { customSizeWidth, customSizeHeight, customSizeUnit } = this.state;
    const MPI = 25.4; // mm, 1 inch = 25.4 mm
    const printExtra = 4; // mm
    const screenPPI = 72;

    if (!(customSizeWidth && customSizeHeight)) {
      return notification.error('Incorrect Custom Size Values');
    }

    if (customSizeUnit === 'inch') {
      customSizeWidth = Number((customSizeWidth * MPI).toFixed(1));
      customSizeHeight = Number((customSizeHeight * MPI).toFixed(1));
    }

    axios
      .post('/format', {
        width: Number(
          (screenPPI * (customSizeWidth + printExtra)) / MPI
        ).toFixed(1),
        height: Number(
          (screenPPI * (customSizeHeight + printExtra)) / MPI
        ).toFixed(1),
        printWidth: customSizeWidth + 4,
        folds: 0,
      })
      .then(({ data }) => {
        this.setState({ customSizeWidth: 0 });
        this.setState({ customSizeHeight: 0 });
        this.setState({ customSizeUnit: 'mm' });
        this.setState({ selectedFormatName: data.format.name });
        this.createFromScratch();
      })
      .catch(err => console.error(err));
  }

  renderMenu(menu, notifyVerificationIfNeed) {
    const wrapperClassName = `menu-wrapper format-${menu.format.name} ${
      menu.id === this.state.mainMenuId ? 'selected' : ''
    }`;
    const pageImagePath = menu.images.split('|')[0];
    const src = pageImagePath.includes('/default.jpeg')
      ? pageImagePath
      : `${pageImagePath
          .split('/')
          .slice(0, -1)
          .join('/')}/preview1.jpeg`;

    // if user is not verified - show message and do not redirect to menu page;
    const href = this.props.showIsVerifiedBanner
      ? MENUS
      : `/app?format=${menu.format.name}&menu=${
          menu.id
        }&template=${menu.template_id || 'false'}&slug=${menu.slug}`;

    return (
      <div className={wrapperClassName} key={menu.id} data-menuid={menu.id}>
        <a href={href} onClick={notifyVerificationIfNeed}>
          <div className={`img-wrapper wrapper-${menu.format.name}`}>
            <ImageFallback
              alt={menu.name}
              height={270}
              src={src}
              width="auto"
            />
          </div>
        </a>
        <span className="menu-format-caption">{menu.format.name}</span>
        <button className="icon-btn star" onClick={this.markAsMain}>
          <span className="glyphicon icon-waitron-star" />
        </button>
        <MenuProperties
          menu={menu}
          country={this.props.user.country}
          actions={this.state.actions}
          onSelect={this.dispatchMenuProperties}
        />
      </div>
    );
  }

  createButton() {
    return (
      <Button
        className="menu-wrapper"
        id="create-menu"
        onClick={this.toggleMenuParametersModal}
      >
        <div>
          <i className="icon-waitron-add-menu" />
          <span className="text">{i18n.t('Create a menu')}</span>
        </div>
      </Button>
    );
  }

  render() {
    const { menus, name, error, activeTab, activeMenu } = this.state;
    const country = this.props.user ? this.props.user.country : '';

    const wrapperFunction = this.props
      .conditionalExecutionWithVerificationBanner;
    const createButtonAction = wrapperFunction(this.toggleMenuParametersModal);

    return (
      <div className="container">
        <div className="title-container">
          <div className="nav-background">
            <div className="nav-block menu-nav-block">
              <NavigationBar />
            </div>
          </div>
        </div>

        <div className="menus-container">
          <Button
            className="menu-wrapper"
            id="create-menu"
            onClick={createButtonAction}
          >
            <div>
              <i className="icon-waitron-add-menu" />
              <span className="text">{i18n.t('Create a menu')}</span>
            </div>
          </Button>
          <MenuSharing
            showModal={this.state.showMenuSharingModal}
            toggle={this.toggleMenuSharingModal}
            menu={this.state.activeMenu}
            country={country}
          />
          <MenuQRCode
            showModal={this.state.showMenuQRCodeModal}
            toggle={this.toggleMenuQRCodeModal}
            menu={this.state.activeMenu}
          />
          <Popup
            showModal={this.state.showMenuParametersModal}
            toggle={this.toggleMenuParametersModal}
            onAccept={
              activeTab === '3'
                ? this.createInCustomSize
                : this.createFromScratch
            }
            confirmButtonTitle="OK"
            title={i18n.t('Menu size')}
          >
            <InputFormGroup
              caption={i18n.t('Menu name')}
              value={name}
              error={error}
              controlId="menu-name-input"
              onInput={this.onNameChanged}
            />
            <FormatPreviewTabs
              onFormatSelected={this.onFormatSelected}
              customSizeChangeWidth={this.customSizeChangeWidth}
              customSizeChangeHeight={this.customSizeChangeHeight}
              customSizeChangeUnit={this.customSizeChangeUnit}
              customSizeUnit={this.state.customSizeUnit}
              selected={this.state.selectedFormatName}
              setActiveTab={this.setActiveTab}
              resize={false}
              isAuth={!!this.props.user}
            />
          </Popup>
          <Popup
            showModal={this.state.showMenuFormatsModal}
            toggle={this.toggleMenuFormatsModal}
            onAccept={activeTab === '3' ? this.resizeToCustomSize : this.resize}
            confirmButtonTitle="OK"
            title={i18n.t('Menu size')}
          >
            <FormatPreviewTabs
              onFormatSelected={this.onFormatSelected}
              customSizeChangeWidth={this.customSizeChangeWidth}
              customSizeChangeHeight={this.customSizeChangeHeight}
              customSizeChangeUnit={this.customSizeChangeUnit}
              selected={this.state.selectedFormatName}
              setActiveTab={this.setActiveTab}
              resize={true}
              isAuth={!!this.props.user}
              activeMenu={activeMenu}
            />
          </Popup>
          {menus.map(menu => this.renderMenu(menu, wrapperFunction()))}
        </div>
      </div>
    );
  }
}

Menus.propTypes = {
  user: PropTypes.shape({
    country: PropTypes.string,
  }),
};

export default Menus;
