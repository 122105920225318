import React from 'react';
import i18n from '../../components/i18n';
import './Error.scss';

export default function ErrorPage() {
  return (
    <div className="error-404">
      <div className="error-wrapper col-lg-offset-4 col-lg-4 col-md-offset-3 col-md-6 col-sm-offset-3 col-sm-6">
        <div className=" row text-404">404</div>
        <div className=" row error-text">{i18n.t('Error text')}</div>
        <div className=" row error-subtext">{i18n.t('Error sub text')}</div>
        <div className=" row error-subtext">
          <a href="/dashboard" className="btn btn-default back-to-dashboard">
            {i18n.t('To main')}
          </a>
        </div>
      </div>
    </div>
  );
}
