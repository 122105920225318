import React, { Component } from 'react';
import axios from 'axios';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import i18n from '../i18n';
import './auth_popup.scss';
import InputFormGroup from '../form_group/InputFormGroup';
import AuthPopup from './AuthPopup';
import AuthPopupFooter from './AuthPopupFooter';
import * as notification from '../../common/notifications';

class ForgotPasswordForm extends Component {
  constructor() {
    super();
    this.state = {
      show: true,
      email: '',
      msg: '',
    };

    this.handleClose = this.handleClose.bind(this);
    this.setEmail = this.setEmail.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  handleClose() {
    this.setState({ show: !this.state.show });
  }

  setEmail(value) {
    this.setState({ email: value });
  }

  onSubmit(e) {
    e.preventDefault();
    const { email } = this.state;
    axios
      .post('/reset_password', {
        onResetEmail: email,
      })
      .then(({ data }) => {
        if (data) {
          this.setState({ msg: true });
        }
      })
      .catch(() => notification.error(i18n.t('Something went wrong')));
  }

  render() {
    const { show, msg } = this.state;
    return (
      <AuthPopup show={show} handleClose={this.handleClose}>
        <div className="for-reset-pass form">
          <h4>{i18n.t('Forgot password')}? </h4>
          <form onSubmit={this.onSubmit}>
            <div className="space-top-bottom">
              <InputFormGroup
                type="email"
                caption={i18n.t('Email')}
                controlId="email-input"
                onInput={this.setEmail}
              />
            </div>
            <div className="space-top-bottom">
              <Button
                className="sign-in-button"
                variant="primary"
                type="submit"
              >
                {i18n.t('Send')}
              </Button>
            </div>
          </form>
          <div>
            {msg && <h4>{i18n.t('Sent! Please check your email')}</h4>}
            <Link to="/sign_in">{i18n.t('Return to Sign In')}</Link>
          </div>
          <AuthPopupFooter />
        </div>
      </AuthPopup>
    );
  }
}

export default ForgotPasswordForm;
