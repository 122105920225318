import './menuProperties.scss';
import React, { Component } from 'react';
import { DropdownButton, MenuItem } from 'react-bootstrap';
import PropTypes from 'prop-types';
import i18n from '../../../i18n';
import { copyToClipboard } from '../../../helpers';

class MenuProperties extends Component {
  constructor(props) {
    super(props);

    this.onSelect = this.onSelect.bind(this);
  }

  onSelect(eventKey, e) {
    this.props.onSelect(eventKey, $(e.currentTarget).attr('data-menuid'));
  }

  // note: do not forget to add `title` translation when adding new item
  menuProperty(menuId, action, title) {
    return (
      <MenuItem
        className="list-item"
        key={`${action}-${menuId}`}
        data-menuid={menuId}
        eventKey={`${action}-menu`}
        onSelect={this.onSelect}
      >
        {i18n.t(title)}
      </MenuItem>
    );
  }

  render() {
    const { menu, country, actions } = this.props;
    let printOption;
    if (country === 'Ukraine' && !menu.format.isUSFormat) {
      printOption = (
        <MenuItem
          className="list-item"
          eventKey="print-menu"
          href={`/print?menu=${menu.id}`}
        >
          {i18n.t('To print')}
        </MenuItem>
      );
    }
    return (
      <div className="menu-properties">
        <span className="menu-name">{menu.name}</span>
        <div className="copy-link-wrapper">
          <button
            className="icon-btn copy-menu-link"
            title={i18n.t('Get a link')}
            value={menu.slug}
            onClick={e =>
              copyToClipboard(
                `${window.location.origin}/menu/${e.currentTarget.value}`
              )
            }
          >
            <i className="icon-waitron-link" />
          </button>
        </div>
        <DropdownButton
          id={`dropdown-custom-${menu.id}`}
          className="icon-btn"
          title={<i className="icon-waitron-more" />}
          noCaret
        >
          {actions.map(action => this.menuProperty(menu.id, action, action))}
          {printOption}
        </DropdownButton>
      </div>
    );
  }
}

MenuProperties.propTypes = {
  country: PropTypes.string,
  actions: PropTypes.arrayOf(PropTypes.string),
  onSelect: PropTypes.func,
  menu: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    format: PropTypes.shape({
      isUSFormat: PropTypes.bool,
    }),
  }),
};

export default MenuProperties;
