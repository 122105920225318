import React, { Component } from 'react';
import {
  LazyLoadImage,
  trackWindowScroll,
} from 'react-lazy-load-image-component';

class Image extends Component {
  constructor(props) {
    super(props);

    this.state = {
      src: this.props.src,
    };
    this.fallbackSRC = '/img/user_menus/default.jpeg';
    this.error = true;

    this.handleError = this.handleError.bind(this);
  }

  handleError(e) {
    console.log(`Missing ${e.currentTarget.src} image`);
    if (this.error) {
      this.error = false;
      this.setState({ src: this.fallbackSRC });
    }
  }

  render() {
    const { src, ...restProps } = this.props;
    return (
      <LazyLoadImage
        placeholderSrc={this.fallbackSRC}
        src={this.state.src}
        onError={this.handleError}
        {...restProps}
      />
    );
  }
}

export default trackWindowScroll(Image);
