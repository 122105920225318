import React from 'react';
import i18n from '../i18n';
import './CustomList.scss';

const CustomList = ({ listItems, icon, className = '' }) => (
  <ul className={`custom-list ${className}`}>
    {listItems.map(item => (
      <li className="custom-list__item" key={item}>
        {icon}
        {i18n.t(item)}
      </li>
    ))}
  </ul>
);

export default CustomList;
