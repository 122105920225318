import React, { Component } from 'react';
import axios from 'axios';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import './auth_popup.scss';
import cookies from 'react-cookies';
import i18n from '../i18n';
import InputFormGroup from '../form_group/InputFormGroup';
import AuthPopup from './AuthPopup';
import AuthPopupFooter from './AuthPopupFooter';
import SocialMediaButtons from './SocialMediaButtons';
import * as notification from '../../common/notifications';
import withRouter from '../../hocs/withRouter';
import { DASHBOARD, BLOCKED } from '../../../constants';

class SignInForm extends Component {
  constructor() {
    super();
    this.state = {
      show: true,
      email: '',
      password: '',
      rememberMe: false,
      error: false,
    };

    this.handleClose = this.handleClose.bind(this);
    this.setEmail = this.setEmail.bind(this);
    this.setPassword = this.setPassword.bind(this);
    this.rememberMe = this.rememberMe.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.clearError = this.clearError.bind(this);
  }

  clearError() {
    this.setState({ error: false });
  }

  handleClose() {
    this.clearError();
    this.setState({ show: !this.state.show });
  }

  setEmail(value) {
    this.clearError();
    this.setState({ email: value });
  }

  setPassword(value) {
    this.clearError();
    this.setState({ password: value });
  }

  rememberMe() {
    this.setState({ rememberMe: !this.state.rememberMe });
  }

  onSubmit(e) {
    e.preventDefault();
    const { show, rememberMe, email, password } = this.state;

    axios
      .post('/login', {
        logInEmail: email,
        logInPassword: password,
        rememberMe,
      })
      .then(res => {
        if (cookies.load('isocc')) {
          this.setState({ show: !show });
          this.props.router.navigate(BLOCKED);
          return;
        }

        const responseURL = res.request.responseURL;
        const result = res.request.responseURL.substr(
          responseURL.lastIndexOf('/')
        );
        if (result !== '/auth') {
          this.setState({ show: !show });
          this.props.router.navigate(DASHBOARD);
          window.location.reload();
        } else {
          this.setState({ error: true });
          notification.error(i18n.t('Wrong email or password'));
        }
      })
      .catch(() => notification.error(i18n.t('Something went wrong')));
  }

  render() {
    const { show, error } = this.state;
    return (
      <AuthPopup show={show} handleClose={this.handleClose}>
        <div className="form">
          <h4>
            {i18n.t('Sign in')} {i18n.t('to')} Waitron.Menu
          </h4>
          <SocialMediaButtons title="Sign In" />
          <div className="separator">{i18n.t('or')}</div>
          <form onSubmit={this.onSubmit}>
            <div className="space-top-bottom">
              <InputFormGroup
                type="email"
                error={error}
                caption={i18n.t('Email')}
                controlId="email-input"
                onInput={this.setEmail}
              />
            </div>
            <div className="space-top-bottom">
              <InputFormGroup
                type="password"
                error={error}
                caption={i18n.t('Password')}
                controlId="password-input"
                onInput={this.setPassword}
              />
            </div>
            <div className="row additional-options">
              <div className="col-xs-6">
                <label className="checkbox-container">
                  <input
                    type="checkbox"
                    id="remember-me"
                    name="rememberMe"
                    onChange={this.rememberMe}
                  />
                  <span></span>
                  {i18n.t('Remember me')}
                </label>
              </div>
              <div className="col-xs-6">
                <Link to="/forgot_password">{i18n.t('Forgot password')}?</Link>
              </div>
            </div>
            <div className="space-top-bottom">
              <Button
                className="sign-in-button"
                variant="primary"
                type="submit"
              >
                {i18n.t('Sign in')}
              </Button>
            </div>
          </form>
          <div>
            <span> {i18n.t("Don't have an account")}? </span>
            <Link to="/sign_up">{i18n.t('Sign up')}</Link>
          </div>
          <AuthPopupFooter />
        </div>
      </AuthPopup>
    );
  }
}

export default withRouter(SignInForm);
