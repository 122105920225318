import React, { useEffect, useState } from 'react';
import withClickOutside from './withClickOutside';
import InputFormGroup from '../form_group/InputFormGroup';

import './select.scss';

const SelectComponent = React.forwardRef(
  (
    {
      controlId,
      options,
      caption = '',
      onChange,
      selectedKey,
      open,
      setOpen,
      onlyLetters = false,
    },
    ref
  ) => {
    const [inputValue, setInputValue] = useState(caption);

    useEffect(() => {
      if (selectedKey) {
        setInputValue(options.find(o => o.key === selectedKey).value);
      }
    }, [selectedKey, options]);

    useEffect(() => {
      if (!open && options.findIndex(o => o.value === inputValue) === -1) {
        if (selectedKey) {
          setInputValue(options.find(o => o.key === selectedKey).value);
        } else {
          setInputValue('');
        }
      }
    }, [open, options, selectedKey, inputValue, onChange]);

    const onInputChange = value => {
      if (onlyLetters) value = value.replace(/[0-9]/g, '');
      setInputValue(value);
    };

    const onInputClick = () => {
      setOpen(prevValue => !prevValue);
    };

    const onOptionSelected = option => {
      onChange !== undefined && onChange(option.key);
      onChange !== undefined && setInputValue(option.value);
      setOpen(false);
    };

    const clearDropdown = () => {
      setInputValue('');
      onChange('');
    };

    return (
      <div className="select-component dropdown-container" ref={ref}>
        <div className="input-container" onClick={onInputClick}>
          <InputFormGroup
            className="form-input"
            type="text"
            value={inputValue}
            caption={caption}
            controlId={controlId}
            onInput={onInputChange}
          />
          <div className="input-arrow-container">
            <i className="input-arrow" />
          </div>

          {selectedKey || inputValue ? (
            <div className="input-clean-container" onClick={clearDropdown}>
              x
            </div>
          ) : null}
        </div>
        <div className={`dropdown ${open ? 'visible' : ''}`}>
          {options
            .filter(item => {
              const searchTerm = inputValue.toLowerCase();
              const v = item.value.toLowerCase();

              if (!searchTerm) return true;

              return v.startsWith(searchTerm);
            })
            .map(opt => (
              <div
                key={opt.key}
                onClick={() => onOptionSelected(opt)}
                className="option"
                value={opt.key}
              >
                {opt.value}
              </div>
            ))}
        </div>
      </div>
    );
  }
);

export default withClickOutside(SelectComponent);
