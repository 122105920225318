import React from 'react';
import cookies from 'react-cookies';
import SubscriptionPlans from '../../components/SubscriptionPlans';
import CustomLink from '../../components/CustomLink';
import i18n from '../../components/i18n';
import saveUserAction from '../../common/saveUserAction';
import { DASHBOARD, SUBSCRIPTION_BILLING } from '../../../constants';

import './SubscriptionMain.scss';

const subscriptionCardsData = [
  {
    key: 'year',
    title: 'Premium Yearly',
    label: 'The best price',
    price: '$3',
    currency: 'USD',
    priceDescription: 'month, paid annually',
  },
  {
    key: 'month',
    title: 'Premium Monthly',
    price: '$5',
    currency: 'USD',
    priceDescription: 'month, paid monthly',
  },
];

const SubscriptionMain = () => {
  const renderSubscriptionCard = subscriptionData => (
    <div className="subscription-main__card" key={subscriptionData.title}>
      <div className="subscription-main__card-top">
        <h3 className="subscription-main__card-title">
          {i18n.t(subscriptionData.title)}
        </h3>
        {!!subscriptionData.label && (
          <span className="subscription-main__card-label">
            {i18n.t(subscriptionData.label)}
          </span>
        )}
      </div>
      <div className="subscription-main__card-price">
        <span className="subscription-main__card-price-value">
          {subscriptionData.price}
        </span>
        <span className="subscription-main__card-price-currency">
          &nbsp;{subscriptionData.currency}
        </span>
        <span className="subscription-main__card-price-description">
          &nbsp;/&nbsp;{i18n.t(subscriptionData.priceDescription)}
        </span>
        <CustomLink
          to={`${SUBSCRIPTION_BILLING}?interval=${subscriptionData.key}`}
          onClick={() => saveUserAction({ lastAction: 'subscribe_now' })}
          label={i18n.t('Subscribe now with 50% off')}
          className={'subscription-main__card-link'}
          isFullWidth
        />
      </div>
    </div>
  );

  const now = new Date();
  const time = now.getTime();

  return (
    <div className="subscription-main">
      <div className="subscription-main__banner">
        <div className="subscription-main__container">
          <h1 className="subscription-main__banner-title">
            {i18n.t('Welcome to Waitron Menu')}
          </h1>
          <p className="subscription-main__banner-description">
            {i18n.t(
              'Design, edit and download restaurant menus without limits with Premium account.'
            )}
          </p>
        </div>
      </div>
      <div className="subscription-main__subscription">
        <div className="subscription-main__container subscription-main__subscription-wrap">
          <SubscriptionPlans className="subscription-main__plans" />
          <div className="subscription-main__cards">
            {subscriptionCardsData.map(data => renderSubscriptionCard(data))}
            <CustomLink
              to={DASHBOARD}
              onClick={() => {
                cookies.save('seenBanner', true, {
                  path: '/',
                  expires: new Date(time + 86400 * 1000),
                });
                saveUserAction({ lastAction: 'continue_for_free' });
              }}
              label={`${i18n.t('Continue for free')} →`}
              className="subscription-main__card-link-bottom"
              isFullWidth
              isFlat
            />
          </div>
        </div>
      </div>
    </div>
  );
};
export default SubscriptionMain;
