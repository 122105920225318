import 'fabric';
import './menuQRCode.scss';

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import QRCode from 'qrcode';

import Popup from '../../../popup/Popup';
import i18n from '../../../i18n';
import * as notification from '../../../../common/notifications';
import Image from '../../../helpers/Image';

class MenuSharing extends Component {
  constructor(props, context) {
    super(props, context);

    this.fabricObj = null;
    this.qrCodeCanvasId = 'qrCodeCanvas';
    this.canvasSize = {
      width: 250,
      height: 250,
    };
    this.screenDPI = 72;
    this.printDPI = 300;
    this.MPI = 25.4;

    this.toggle = this.toggle.bind(this);
    this.state = {
      sharedLink: '',
      embedCode: '',
      twitterLink: '',
      facebookLink: '',
      pinterestLink: '',
      vkLink: '',
      mailLink: '',
    };
  }

  toggle() {
    this.props.toggle();
  }

  static createFabricObject(canvasId) {
    const fabricObj = new fabric.Canvas(canvasId, {
      preserveObjectStacking: true,
      backgroundColor: '#fff',
    });
    fabric.Object.prototype.set({
      transparentCorners: false,
      borderDashArray: [7, 7],
      cornerColor: '#ffffff',
      cornerStrokeColor: '#d71f49',
      borderColor: '#d71f49',
      cornerStyle: 'circle',
      cornerSize: 12,
      padding: 5,
    });
    return fabricObj;
  }

  download(name) {
    const dataURL = this.fabricObj.toDataURL({
      format: 'jpeg',
      multiplier: this.getPrintReadyMultiplier(this.canvasSize.width),
    });
    const qrCodeImage = new Image(`QR_code_${name}`, 'image/jpeg', dataURL);
    qrCodeImage.downloadFile();
  }

  displayQRCodeCanvas(menuLink, menuName) {
    this.fabricObj = this.constructor.createFabricObject(this.qrCodeCanvasId);
    const QRCodeImageData = this.constructor.generateQRCodeImageData(menuLink);

    QRCodeImageData.then(dataURL => this.addQRCodeToCanvas(dataURL))
      .then(() => this.addQMenuNameToCanvas(menuName))
      .catch(err => {
        notification.error(i18n.t('Something went wrong'));
        console.error(err);
      });
  }

  addQRCodeToCanvas(qrCodeImageData) {
    return new Promise((resolve, reject) => {
      fabric.Image.fromURL(qrCodeImageData, img => {
        if (img) {
          img.set({
            top: this.fabricObj.height / 4,
            left: this.fabricObj.width / 2 - (img.width * img.scaleX) / 2,
          });
          this.fabricObj.add(img);
          return resolve(img);
        }
        return reject();
      });
    });
  }

  addQMenuNameToCanvas(menuName) {
    const textField = new fabric.IText(menuName, { fontSize: 25 });
    textField.set({
      top: (textField.height * textField.scaleX) / 2,
      left: this.fabricObj.width / 2 - (textField.width * textField.scaleX) / 2,
    });
    this.fabricObj.add(textField).setActiveObject(textField);
  }

  static generateQRCodeImageData(menuLink) {
    return QRCode.toDataURL(menuLink);
  }

  getPrintReadyMultiplier(screenWidth) {
    const printWidth = this.getPrintSize(screenWidth); // note: mm
    return (
      (printWidth / ((this.canvasSize.width / this.screenDPI) * this.MPI)) *
      (this.printDPI / this.screenDPI)
    );
  }

  getPrintSize(screenSize) {
    return (screenSize * this.MPI) / this.screenDPI;
  }

  render() {
    if (!this.props.menu) return null;
    const menuLink = `${window.location.origin}/menu/${this.props.menu.slug}`;
    const menuName = this.props.menu.name;

    return (
      <Popup
        showModal={this.props.showModal}
        toggle={this.toggle}
        onEnter={() => this.displayQRCodeCanvas(menuLink, menuName)}
        onAccept={() => this.download(menuName)}
        confirmButtonTitle={i18n.t('Download')}
        title={i18n.t('get-qr-code')}
      >
        <canvas
          id={this.qrCodeCanvasId}
          width={this.canvasSize.width}
          height={this.canvasSize.height}
        />
      </Popup>
    );
  }
}

MenuSharing.propTypes = {
  toggle: PropTypes.func,
  showModal: PropTypes.bool,
  menu: PropTypes.shape({
    slug: PropTypes.string,
  }),
};

export default MenuSharing;
