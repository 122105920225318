import React, { Component } from 'react';
import {
  createRoutesFromElements,
  createBrowserRouter,
  RouterProvider,
  Navigate,
  Route,
  Outlet,
} from 'react-router-dom';
import axios from 'axios/index';
import cookies from 'react-cookies';
import PropTypes from 'prop-types';
import Templates from './templates/index';
import Menus from './menus/index';
import SignInForm from '../auth_popups/SignInForm';
import SignUpForm from '../auth_popups/SignUpForm';
import ForgotPasswordForm from '../auth_popups/ForgotPasswordForm';
import SubscriptionMain from '../../pages/SubscriptionMain/SubscriptionMain';
import SubscriptionSuccesses from '../../pages/SubscriptionSuccesses/SubscriptionSuccesses';
import SubscriptionBilling from '../../pages/SubscriptionBilling/SubscriptionBilling';
import ErrorPage from '../../pages/Error/Error';
import MainLayout from '../../layouts/mainLayout';
import PopUpsContainer from './popups/popups';
import * as notification from '../../common/notifications';
import i18n from '../i18n';
import saveUserAction from '../../common/saveUserAction';
import { isShowSubscriptionFlow } from '../helpers';
import { DASHBOARD } from '../../../constants';
import { withUserContext } from '../../hocs/withUserContext';

class App extends Component {
  constructor() {
    super();

    this.state = {
      show: false,
      typePopup: null, // subscribe || reminder || business
    };
  }

  userHasBusiness = async () => {
    if (this.state.show) {
      return;
    }

    const { data } = await axios.get('/user-has-business');

    if (!data.hasBusiness && data.reasonCode === 'user_has_no_business') {
      this.setState(() => ({
        typePopup: 'business',
        show: true,
      }));
    }
  };

  onCloseBusinessPopup = () => {
    this.setState(() => ({
      typePopup: null,
      show: false,
    }));
  };

  componentDidMount() {
    if (window.location.href.includes('/billing?isFromEmail=true')) {
      cookies.save('isFromEmail', true, { path: '/' });
    }

    if (cookies.load('email_confirmed')) {
      cookies.remove('email_confirmed');
      notification.success(i18n.t('Great! Your email was confirmed'));
    }

    window.addEventListener('load', this.userHasBusiness);
  }

  componentWillUnmount() {
    window.removeEventListener('load', this.userHasBusiness);
  }

  redirectToBlockPage(action) {
    window.location.href = `/blocked?action=${action}`;
  }

  userNeedVerification = user => {
    if (!user) return null;

    return user.is_need_verification;
  };

  notifyAboutVerification = user => {
    notification.warning(`${i18n.t('Profile Verify Message')} ${user.email}`, {
      title: i18n.t('Warning'),
    });
  };

  componentDidUpdate(prevProps) {
    if (this.props.userContext.user && !prevProps.userContext.user) {
      this.getUserSessionData(this.props.userContext.user);
    }
  }

  getUserSessionData = user => {
    if (user.is_deleted) {
      notification.info('Your account has been blocked!');
    }

    if (cookies.load('isocc')) {
      this.redirectToBlockPage('signin');
      return;
    }

    const country = user.country;

    if (
      country.toLocaleLowerCase().includes('belarus') ||
      country.toLocaleLowerCase().includes('russia')
    ) {
      this.redirectToBlockPage('signin');
      return;
    }

    if (isShowSubscriptionFlow(user)) {
      const isSubscriptionPage = window.location.pathname.includes(
        'subscription'
      );

      if (!isSubscriptionPage) {
        this.setDefaultBanner(user);
      }

      if (cookies.load('isFromEmail') && user) {
        this.setActionIsFromEmail({
          user,
          cookie: cookies.load('isFromEmail'),
        });
      }
    } else {
      this.setState(oldState => ({
        ...oldState,
        show: null,
      }));
    }
  };

  setDefaultBanner(user) {
    if (this.isShowSubsribePopupForOldUser(user)) {
      this.setState(oldState => ({
        ...oldState,
        show: true,
        typePopup: 'subscribe',
      }));

      saveUserAction({
        lastAction: 'seen_subscribe_popup',
      });
    } else {
      const isShowReminder =
        !user.is_new &&
        user.last_action !== 'confirm_pre_order' &&
        !cookies.load('seenBanner');

      this.setState(oldState => ({
        ...oldState,
        show: isShowReminder,
        typePopup: 'reminder',
      }));

      if (isShowReminder) {
        saveUserAction({
          lastAction: 'seen_popup_reminder',
        });
      }
    }
  }

  setActionIsFromEmail() {
    axios
      .post('/subscriptions_actions/create_or_update', {
        lastAction: 'from_email',
      })
      .then(() => {
        cookies.remove('isFromEmail');
      });
  }

  handleTemplateOpen = (e, user) => {
    const templateId = e.currentTarget.getAttribute('data-templateid');
    const isVip = e.currentTarget.getAttribute('data-template-is-price');

    if (!user) {
      cookies.save('menuParams', { templateId }, { path: '/' });
      window.location.href = '/auth';
      return;
    }

    if (this.userNeedVerification(user)) {
      this.notifyAboutVerification(user);
      return;
    }

    const showSubscriptionToNewUsers =
      isVip &&
      user.last_action !== 'confirm_pre_order' &&
      isShowSubscriptionFlow(user);

    if (showSubscriptionToNewUsers) {
      this.setState({
        show: true,
        typePopup: 'subscribe',
      });

      saveUserAction({
        lastAction: 'seen_subscribe_popup',
      });
      return;
    }

    axios
      .post('/menu/from_template', {
        templateId,
      })
      .then(({ data }) => {
        window.location.href = `/app?format=${data.format}&menu=${data.id}&template=${templateId}&slug=${data.slug}`;
      });
  };

  handlePopUpClose(props) {
    const user = props.userContext.user;

    const now = new Date();
    const time = now.getTime();

    if (!user.is_new) {
      cookies.save('seenBanner', true, {
        path: '/',
        expires: new Date(time + 86400 * 1000),
      });
    }

    this.setState({ show: false, typePopup: null });
    saveUserAction({
      lastAction: props.lastAction,
    });
  }

  isShowSubsribePopupForOldUser(user) {
    if (user) {
      const isFromEmailCookie = cookies.load('isFromEmail');
      const { is_from_email, seen_bunner, is_new, last_action } = user;

      return (
        !is_new &&
        !seen_bunner &&
        !(is_from_email || isFromEmailCookie) &&
        last_action !== 'confirm_pre_order'
      );
    }

    return false;
  }

  render() {
    const user = this.props.userContext.user;

    const conditionalExecutionWithVerificationBanner = func => {
      // wraper to execute logic only if user is verified
      if (!this.userNeedVerification(user))
        return (...params) => func && func(...params);

      return () => this.notifyAboutVerification(user);
    };

    const isSubscription = user && isShowSubscriptionFlow(user);

    const router = createBrowserRouter(
      createRoutesFromElements(
        <Route path="/" errorElement={<ErrorPage />}>
          <Route
            element={
              <MainLayout
                showBannerToVerify={this.userNeedVerification(user)}
                user={user}
              />
            }
          >
            <Route
              path="/subscription"
              element={
                !isSubscription ? (
                  <Navigate replace to={DASHBOARD} />
                ) : (
                  <Outlet />
                  // render index
                )
              }
            >
              <Route index element={<SubscriptionMain />} />
              <Route path="successes" element={<SubscriptionSuccesses />} />
              <Route
                path="billing"
                element={
                  <SubscriptionBilling
                    conditionalExecutionWithVerificationBanner={
                      conditionalExecutionWithVerificationBanner
                    }
                    user={user}
                  />
                }
              />
            </Route>

            <Route path={DASHBOARD} element={<Outlet />}>
              <Route
                index
                element={
                  <Templates
                    user={user}
                    handleOpen={e => {
                      this.handleTemplateOpen(e, user);
                    }}
                  />
                }
              />

              <Route
                path="templates"
                element={<Navigate replace to={DASHBOARD} />}
              />

              <Route
                path="menus"
                element={
                  <Menus
                    user={user}
                    showIsVerifiedBanner={this.userNeedVerification(user)}
                    conditionalExecutionWithVerificationBanner={
                      conditionalExecutionWithVerificationBanner
                    }
                  />
                }
              />
            </Route>

            <Route exact path="sign_in" element={<SignInForm />} />
            <Route exact path="sign_up" element={<SignUpForm />} />
            <Route
              exact
              path="forgot_password"
              element={<ForgotPasswordForm />}
            />
          </Route>
        </Route>
      )
    );

    return (
      <>
        <PopUpsContainer
          show={this.state.show}
          typePopup={this.state.typePopup}
          actions={{
            onCloseBusinessPopup: this.onCloseBusinessPopup.bind(this),
            handlePopUpClose: this.handlePopUpClose.bind(this),
          }}
        />
        <RouterProvider router={router} />
      </>
    );
  }
}

App.propTypes = {
  userContext: PropTypes.shape({
    user: PropTypes.object,
    setUser: PropTypes.func,
    loading: PropTypes.bool,
  }),
};

export default withUserContext(App);
