import React from 'react';
import { Modal } from 'react-bootstrap';
import './auth_popup.scss';

const AuthPopup = props => (
  <Modal
    size="xl"
    show={props.show}
    onHide={props.handleClose}
    backdrop="static"
  >
    <div className="flex-blok">
      <span className="close-btn" onClick={props.handleClose}>
        <a href="#" className="close" />
      </span>
      <div className="left">
        <div className="logo">
          <img src="/img/landing/logo-footer.svg" />
        </div>
      </div>
      {props.children}
    </div>
  </Modal>
);
export default AuthPopup;
