import React from 'react';
import CustomList from '../CustomList';
import i18n from '../i18n';
import './SubscriptionPlans.scss';

const features = {
  freePlan: [
    '12 free templates',
    'Free pictures & icons',
    'Brand customization (colors, fonts, figures)',
    'Your own images upload',
    'Downloading, sharing and embedding on website options',
  ],
  premiumPlan: [
    'Unlimited access to all designs',
    'All pictures & icons',
    'Unlimited access to all sizes & menu formats',
    'Your own images upload',
    'Brand customization (colors, fonts, figures)',
    'Unlimited QR code downloads',
    'Downloading, sharing and embedding on website options',
  ],
};

const SubscriptionPlans = ({ className = '' }) => (
  <div className={`subscription-plans ${className}`}>
    <div className="subscription-plans__plan">
      <h2 className="subscription-plans__plan-title">{i18n.t('Free')}</h2>
      <CustomList
        className="subscription-plans__features"
        listItems={features.freePlan}
        icon={<img src="img/landing/check.svg" width={17} height={12} alt="" />}
      />
    </div>
    <div className="subscription-plans__plan">
      <h2 className="subscription-plans__plan-title subscription-plans__plan-title--premium">
        {i18n.t('Premium')}
      </h2>
      <CustomList
        className="subscription-plans__features"
        listItems={features.premiumPlan}
        icon={
          <img src="img/landing/check-red.svg" width={17} height={12} alt="" />
        }
      />
    </div>
  </div>
);
export default SubscriptionPlans;
