import axios from 'axios/index';

export default function saveBilling({ email, firstName, lastName, country }) {
  axios
    .post('/billings', {
      email,
      firstName,
      lastName,
      country,
    })
    .then(({ data }) => {
      console.log({ data });
    })
    .catch(err => console.error(err));
}
