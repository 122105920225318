import React from 'react';
import { Modal } from 'react-bootstrap';
import CountDown from '../CountDown';
import i18n from '../i18n';
import CustomList from '../CustomList';
import Price from '../Price';

import './reminder_popups.scss';
import {
  subscriptionTestingFinishDate,
  subscriptionTestingFinished,
} from '../helpers';
import CustomLink from '../CustomLink';
import { SUBSCRIPTION_BILLING } from '../../../constants';

const features = {
  freePlan: [
    i18n.t('12 free templates'),
    i18n.t('37 paid designs - $9/template'),
  ],
  premiumPlan: [
    i18n.t('49 menu templates'),
    i18n.t('all sizes & menu formats'),
    i18n.t('QR code feature'),
  ],
};

const ReminderPopup = props => {
  const targetDate = subscriptionTestingFinishDate;

  const Card = ({ src, children }) => (
    <div className="card">
      <div className="image-container">
        <img
          src={src}
          alt={`menu-template-${src.split('/')[2]}`}
          className="image"
        />
      </div>
      <div className="card-children">{children}</div>
    </div>
  );

  const handleSubscribe = () => {
    props.handleClose({ lastAction: 'subscribe_from_reminder' });
    window.location.href = SUBSCRIPTION_BILLING;
  };

  return (
    <Modal
      show={props.show && subscriptionTestingFinished()}
      onHide={() => props.handleClose({ lastAction: 'close_popup_reminder' })}
      backdrop="static"
      dialogClassName="reminder-modal"
    >
      <div className="remainder-popup-container">
        <span
          className="close-btn"
          onClick={() =>
            props.handleClose({ lastAction: 'close_popup_reminder' })
          }
        >
          <a href="#" className="close" />
        </span>
        <h1>
          <span></span>
          <span className="text-gradient">
            {i18n.t('Last chance to get Waitron Premium')}{' '}
          </span>{' '}
          {i18n.t('with 50% discount')}
        </h1>
        <div className="countdown-container">
          <CountDown targetDate={targetDate}></CountDown>
        </div>

        <div className="card-container">
          <Card src="/img/subscription/menu-name1.png">
            <Price
              price={0}
              currency="USD"
              recurringInterval={i18n.t('monthly')}
              className="reminder-modal-price"
            />

            <CustomList
              className="list"
              listItems={features.freePlan}
              icon={
                <img
                  src="img/landing/check-red.svg"
                  width={17}
                  height={12}
                  alt=""
                />
              }
            />
          </Card>
          <Card src="/img/subscription/menu-name.png">
            <Price
              price={5}
              currency="USD"
              recurringInterval={i18n.t('monthly')}
              discount={0.5}
              label="Save"
              saveAmount="330$"
              className="reminder-modal-price"
            />
            <div className="list">
              <p>{i18n.t('Unlimited access to')}:</p>
              <CustomList
                listItems={features.premiumPlan}
                icon={
                  <img
                    src="img/landing/check-red.svg"
                    width={17}
                    height={12}
                    alt=""
                  />
                }
              />
            </div>
          </Card>
        </div>
        <div className="link-section">
          <CustomLink
            to="/"
            onClick={() => handleSubscribe()}
            label={i18n.t('Subscribe')}
            className={'link btn-fill'}
            isFullWidth
          />
          <button
            className="link btn-none"
            onClick={() =>
              props.handleClose({ lastAction: 'stay_on_basic_plan' })
            }
          >
            {i18n.t('Stay on basic account')}
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default ReminderPopup;
