import React from 'react';
import { NavLink } from 'react-router-dom';
import i18n from '../../i18n';
import './navigation-bar.scss';

const NavigationBar = () => {
  const options = [
    {
      title: 'My menus',
      to: '/dashboard/menus',
      logo: 'icon-waitron-template',
    },
    {
      title: 'Templates',
      to: '/dashboard',
      logo: 'icon-waitron-mockups',
    },
  ];

  return (
    <div className="deshbord-navigation-bar">
      {options.map(({ title, to, logo }) => (
        <NavLink
          key={title}
          to={to}
          end={true}
          className={({ isActive }) => (isActive && 'active') || ''}
        >
          <div data-title={i18n.t(title)}>
            <i className={logo} />
            <span>{i18n.t(title)}</span>
          </div>
        </NavLink>
      ))}
    </div>
  );
};

export default NavigationBar;
