import 'bootstrap/dist/js/bootstrap.min';
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.scss';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './dashboard/App.jsx';
import './i18n.js';

import { UserProvider } from '../hocs/withUserContext';

ReactDOM.render(
  <UserProvider>
    <App />
  </UserProvider>,
  document.getElementById('root')
);
