import axios from 'axios';
import React, { Component } from 'react';

function withFormats(ChildrenComponent) {
  return class extends Component {
    constructor(props) {
      super(props);

      this.state = {
        formats: {
          euFormats: [],
          usFormats: [],
        },
      };
    }

    componentDidMount() {
      this.get();
    }

    get() {
      axios
        .get('/format?type=fixed')
        .then(({ data }) =>
          this.setState({
            formats: {
              euFormats: data.formats.filter(format => !format.isUSFormat),
              usFormats: data.formats.filter(format => format.isUSFormat),
            },
          })
        )
        .catch(err => console.error(err));
    }

    render() {
      return <ChildrenComponent formats={this.state.formats} {...this.props} />;
    }
  };
}

export default withFormats;
