import React from 'react';
import { DASHBOARD } from '../../../constants';
import CustomLink from '../../components/CustomLink';
import i18n from '../../components/i18n';

import './SubscriptionSuccesses.scss';

const SubscriptionSuccesses = () => (
  <div className="subscription-successes">
    <div className="subscription-successes__container">
      <h1 className="title">
        {i18n.t('Thank you for choosing')} <br />
        <span className="text-gradient title__sub-title">Waitron Premium</span>
      </h1>
      <p className="description">
        {i18n.t('Your subscription will be charged on 4 March 2023.')}
        <br />
        {i18n.t(
          'You`ll receive a confirmation email about payment processing.'
        )}
      </p>
      <CustomLink to={DASHBOARD} label={i18n.t('Back to menu design')} />
    </div>
  </div>
);
export default SubscriptionSuccesses;
