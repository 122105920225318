import React, { Component } from 'react';
import axios from 'axios';
import cookies from 'react-cookies';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import ReCAPTCHA from 'react-google-recaptcha';
import i18n from '../i18n';
import './auth_popup.scss';
import InputFormGroup from '../form_group/InputFormGroup';
import AuthPopup from './AuthPopup';
import AuthPopupFooter from './AuthPopupFooter';
import SocialMediaButtons from './SocialMediaButtons';
import * as notification from '../../common/notifications';
import { DASHBOARD, BLOCKED } from '../../../constants';
import withRouter from '../../hocs/withRouter';

const recaptchaRef = React.createRef();

class SignUpForm extends Component {
  constructor() {
    super();
    this.state = {
      lang: cookies.load('lang') === 'undefined' ? 'en' : cookies.load('lang'),
      show: true,
      userName: '',
      email: '',
      password: '',
      receiveNews: false,
      agreeWith: false,
      captcha: null,
      categories: [],
      businessName: '',
      businessWebsite: '',
      checkedCategories: [],
      businessAddress: '',
    };

    this.handleClose = this.handleClose.bind(this);
    this.handleCaptcha = this.handleCaptcha.bind(this);
    this.setUserName = this.setUserName.bind(this);
    this.setEmail = this.setEmail.bind(this);
    this.setPassword = this.setPassword.bind(this);
    this.receiveNews = this.receiveNews.bind(this);
    this.agreeWith = this.agreeWith.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.setCheckedCategories = this.setCheckedCategories.bind(this);
    this.setBusinessName = this.setBusinessName.bind(this);
    this.setBusinessWebsite = this.setBusinessWebsite.bind(this);
    this.setBusinessAddress = this.setBusinessAddress.bind(this);
  }

  componentDidMount() {
    recaptchaRef.current.execute();
    this.fetchCategories();
  }

  fetchCategories() {
    axios.get('/business-categories').then(({ data }) => {
      if (data) {
        this.setState({ categories: data });
      }
    });
  }

  handleClose() {
    this.setState({ show: !this.state.show });
  }

  handleCaptcha(value) {
    this.setState({ captcha: value });
  }

  setUserName(value) {
    this.setState({ userName: value });
  }

  setEmail(value) {
    this.setState({ email: value });
  }

  setPassword(value) {
    this.setState({ password: value });
  }

  setBusinessName(value) {
    this.setState({ businessName: value });
  }

  setBusinessWebsite(value) {
    this.setState({ businessWebsite: value });
  }

  setBusinessAddress(value) {
    this.setState({ businessAddress: value });
  }

  setCheckedCategories(value) {
    this.setState(prevState => {
      const checkedCategories = [...prevState.checkedCategories];

      if (checkedCategories.includes(value)) {
        checkedCategories.splice(checkedCategories.indexOf(value), 1);
      } else {
        checkedCategories.push(value);
      }

      return { checkedCategories };
    });
  }

  receiveNews() {
    this.setState({ receiveNews: !this.state.receiveNews });
  }

  agreeWith() {
    this.setState({ agreeWith: !this.state.agreeWith });
  }

  onSubmit(e) {
    e.preventDefault();
    const recaptchaValue = recaptchaRef.current.getValue();
    const {
      show,
      userName,
      email,
      password,
      receiveNews,
      agreeWith,
      captcha,
      businessName,
      businessWebsite,
    } = this.state;

    axios
      .post('/signup', {
        userName,
        'g-recaptcha-response': captcha || recaptchaValue,
        signUpEmail: email,
        signUpPassword: password,
        acceptPrivacyPolicy: agreeWith ? 'on' : undefined,
        sendNewsletters: receiveNews ? 'on' : undefined,
        businessName,
        businessWebsite,
        categories: this.state.checkedCategories,
        businessAddress: this.state.businessAddress,
      })
      .then(({ data }) => {
        if (cookies.load('isocc')) {
          this.setState({ show: !show });
          this.props.router.navigate(BLOCKED);
          return;
        }
        if (cookies.load('sm_account_exists')) {
          cookies.remove('sm_account_exists');
          notification.warning(i18n.t('SM Account Exists'));
          return;
        }

        this.setState({ show: !show });
        this.props.router.navigate(data.redirectTo || DASHBOARD);
      })
      .catch(() => notification.error(i18n.t('Something went wrong')));
  }

  render() {
    const { show, lang } = this.state;
    const GOOGLE_RECAPTCHA_SITE_KEY =
      '6Lf2fnQUAAAAAHxjQBL9g8V6ZjK1nK60sdw_BszK';

    return (
      <AuthPopup show={show} handleClose={this.handleClose}>
        <div className="form">
          <h4>
            {i18n.t('Sign up')} {i18n.t('to')} Waitron.Menu
          </h4>
          <SocialMediaButtons title="Sign Up" />
          <div className="separator">{i18n.t('or')}</div>
          <form id="signUpForm" onSubmit={this.onSubmit}>
            <div className="space-top-bottom">
              <InputFormGroup
                caption={i18n.t('Full Name')}
                controlId="full-name-input"
                onInput={this.setUserName}
              />
            </div>
            <div className="space-top-bottom">
              <InputFormGroup
                type="email"
                caption={i18n.t('Email')}
                controlId="email-input"
                onInput={this.setEmail}
              />
            </div>
            <div className="space-top-bottom">
              <InputFormGroup
                type="password"
                caption={i18n.t('Password')}
                controlId="password-input"
                onInput={this.setPassword}
              />
            </div>

            <div
              style={{ marginBottom: '1.5rem', fontWeight: 'bold' }}
              className="text-center"
            >
              {i18n.t('Please add your business details')}
            </div>

            <div className="space-top-bottom">
              <InputFormGroup
                type="businessName"
                caption={i18n.t('Restaurant or business name')}
                controlId="business-name-input"
                onInput={this.setBusinessName}
              />
            </div>
            <div className="space-top-bottom">
              <InputFormGroup
                type="businessWebsite"
                caption={i18n.t('Website or social media link')}
                controlId="business-website-input"
                onInput={this.setBusinessWebsite}
              />
            </div>

            <div className="space-top-bottom">
              <InputFormGroup
                type="businessAddress"
                caption={i18n.t('Address')}
                controlId="business-address-input"
                onInput={this.setBusinessAddress}
              />
            </div>

            <div
              style={{ marginBottom: '1.5rem', fontWeight: 'bold' }}
              className="text-center"
            >
              {i18n.t('Type of establishment')}
            </div>

            <div className="row additional-options">
              {this.state.categories.map(category => (
                <label key={category.id} className="checkbox-container">
                  <input
                    checked={this.state.checkedCategories.includes(category.id)}
                    onChange={() => this.setCheckedCategories(category.id)}
                    id={`businessCategory-${category.id}`}
                    name="businessCategory"
                    type="checkbox"
                  />
                  <span />
                  {category.name}
                </label>
              ))}
            </div>

            <hr />

            <div className="row additional-options">
              <label className="checkbox-container">
                <input
                  type="checkbox"
                  id="agreeWith"
                  name="agreeWith"
                  onChange={this.agreeWith}
                  required
                />
                <span />
                {i18n.t('Accept Privacy Policy')}
              </label>
              <label className="checkbox-container">
                <input
                  type="checkbox"
                  id="receiveNews"
                  name="receiveNews"
                  onChange={this.receiveNews}
                />
                <span />
                {i18n.t('Send newsletters')}
              </label>
            </div>
            <div className="space-top-bottom">
              <Button
                className="sign-in-button"
                variant="primary"
                type="submit"
              >
                {i18n.t('Finish registration')}
              </Button>
            </div>
          </form>
          <div>
            <span> {i18n.t('Already have an account')}? </span>
            <Link to="/sign_in">{i18n.t('Sign in')}</Link>
          </div>
          <div className="space-top-bottom">
            <ReCAPTCHA
              className="g-recaptcha"
              ref={recaptchaRef}
              hl={lang}
              size="invisible"
              badge="inline"
              sitekey={GOOGLE_RECAPTCHA_SITE_KEY}
              onChange={this.handleCaptcha}
            />
            <AuthPopupFooter />
          </div>
        </div>
      </AuthPopup>
    );
  }
}

export default withRouter(SignUpForm);
