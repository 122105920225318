import './formatPreviewTabs.scss';
import React, { Component } from 'react';
import i18n from '../../../i18n';
import InputFormGroup from '../../../form_group/InputFormGroup';
import { toFixedTwo } from '../../../helpers';

const limits = {
  mm: {
    max: {
      width: 594,
      height: 990,
    },
    min: {
      width: 99,
      height: 210,
    },
  },
  inch: {
    max: {
      width: 23.3,
      height: 38.9,
    },
    min: {
      width: 3.8,
      height: 8.2,
    },
  },
};

class CustomSizeTab extends Component {
  constructor(props) {
    super(props);
    this.state = {
      customSizeUnit: 'mm',
      inputWidthError: false,
      inputHeightError: false,
      width: '',
      maxWidth: limits.mm.max.width,
      minWidth: limits.mm.min.width,
      height: '',
      maxHeight: limits.mm.max.height,
      minHeight: limits.mm.min.height,
    };

    this.onCustomSizeChangeWidth = this.onCustomSizeChangeWidth.bind(this);
    this.onCustomSizeChangeHeight = this.onCustomSizeChangeHeight.bind(this);
    this.onCustomSizeChangeUnit = this.onCustomSizeChangeUnit.bind(this);
  }

  static mmToPx(valueInMm) {
    return toFixedTwo((72 * (valueInMm + 4)) / 25.4);
  }

  static pxToMm(valueInPx) {
    return Math.round((valueInPx * 25.4) / 72 - 4);
  }

  onCustomSizeChangeWidth(value) {
    value = Number(value);
    let newHeight = null;
    this.setState({ width: value });
    const condition =
      !isNaN(value) &&
      value <= this.state.maxWidth &&
      value >= this.state.minWidth;

    if (this.props.resize && this.props.activeMenu) {
      const { width, height } = this.props.activeMenu.format;
      newHeight = (height * this.constructor.mmToPx(value)) / width; // px
    }

    if (condition) {
      this.setState({ inputWidthError: false });
      this.props.customSizeChangeWidth(Number(value));
      if (this.props.resize) {
        this.setState({ height: this.constructor.pxToMm(newHeight) });
        this.props.customSizeChangeHeight(this.constructor.pxToMm(newHeight));
      }
    } else {
      this.setState({ inputWidthError: true });
      this.props.customSizeChangeWidth('');
    }
  }

  onCustomSizeChangeHeight(value) {
    value = Number(value);
    let newWidth = null;
    this.setState({ height: value });
    const condition =
      !isNaN(value) &&
      value <= this.state.maxHeight &&
      value >= this.state.minHeight;

    if (this.props.resize && this.props.activeMenu) {
      const { width, height } = this.props.activeMenu.format;
      newWidth = (width * this.constructor.mmToPx(value)) / height; // px
    }
    if (condition) {
      this.setState({ inputHeightError: false });
      this.props.customSizeChangeHeight(Number(value));
      if (this.props.resize) {
        let width = this.constructor.pxToMm(newWidth);
        if (this.state.minWidth > width) {
          width = this.state.minWidth;
        } else if (this.constructor.pxToMm(newWidth) > this.state.maxWidth) {
          width = this.state.maxWidth;
        }
        this.setState({ width });
        this.props.customSizeChangeWidth(width);
      }
    } else {
      this.setState({ inputHeightError: true });
      this.props.customSizeChangeHeight('');
    }
  }

  onCustomSizeChangeUnit(e) {
    this.setState({
      customSizeUnit: e.currentTarget.id,
      maxWidth: limits[e.currentTarget.id].max.width,
      minWidth: limits[e.currentTarget.id].min.width,
      maxHeight: limits[e.currentTarget.id].max.height,
      minHeight: limits[e.currentTarget.id].min.height,
    });
    this.props.customSizeChangeUnit(e);
    this.setState({ inputWidthError: true });
    this.setState({ inputHeightError: true });
  }

  render() {
    const units = ['mm', 'inch'];
    const { width, height, inputWidthError, inputHeightError } = this.state;
    return (
      <div className="custom-format-container" style={{ textAlign: 'center' }}>
        <div className="fields">
          <div>
            <InputFormGroup
              type="text"
              caption={i18n.t('Width')}
              error={inputWidthError}
              value={width.toString()}
              maxLength={this.state.customSizeUnit === 'mm' ? '3' : '2'}
              controlId="width-input"
              onInput={this.onCustomSizeChangeWidth}
            />
          </div>
          <div>
            <InputFormGroup
              type="text"
              caption={i18n.t('Height')}
              error={inputHeightError}
              value={height.toString()}
              maxLength={this.state.customSizeUnit === 'mm' ? '3' : '2'}
              controlId="height-input"
              onInput={this.onCustomSizeChangeHeight}
            />
          </div>
          <div style={{ paddingTop: '5px' }}>
            <div className="dropdown">
              <button
                className="icon-btn dropdown-toggle"
                data-toggle="dropdown"
              >
                <span>{this.state.customSizeUnit}</span>
                <i className="icon-waitron-dropdown"></i>
              </button>
              <ul
                id="format-list"
                className="nav dropdown-menu"
                style={{
                  right: 0,
                  left: 'auto',
                }}
              >
                {units.map(unit => (
                  <li
                    id={unit}
                    key={unit}
                    onClick={this.onCustomSizeChangeUnit}
                  >
                    {i18n.t(unit)}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
        <div
          style={{
            fontSize: 10,
            lineHeight: 1.3,
            textAlign: 'left',
            color: '#c0c0c0',
            display: 'inline-block',
          }}
        >
          {i18n.t('Width limits note')}{' '}
          <strong>
            {this.state.minWidth} {i18n.t('and')} {this.state.maxWidth}
          </strong>{' '}
          {i18n.t(this.state.customSizeUnit)}.<br />
          {i18n.t('Height limits note')}{' '}
          <strong>
            {this.state.minHeight} {i18n.t('and')} {this.state.maxHeight}
          </strong>{' '}
          {i18n.t(this.state.customSizeUnit)}
        </div>
        <div style={{ display: 'flex' }}>
          <img src="/img/favicon/formats/icon-a3.svg" alt="preview" />
        </div>
      </div>
    );
  }
}

export default CustomSizeTab;
