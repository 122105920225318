import React from "react";
import PropTypes from "prop-types";
import * as notification from "../../../common/notifications";
import i18n from "../../i18n";

import "./disclaimer.scss";

const sendVerificationEmail = async () => {
  try {
    const res = await fetch("/email/verification_email", { method: "post" });
    const body = await res.json();

    if (body.status !== "ok") {
      notification.error(`${body.error}`);
      return;
    }

    notification.success(i18n.t("Sent! Please check your email"));
  } catch (error) {
    notification.error(`Internal error, ${error}`);
  }
};

const Disclaimer = ({ email }) => {
  return (
    <div className="disclaimer_container">
      {i18n.t("Profile Verify Message")}
      &nbsp;
      <b>{email}</b>
      &nbsp;
      <button className="disclaimer_button" onClick={sendVerificationEmail}>
        {i18n.t("Confirm Email")}
      </button>
    </div>
  );
};

Disclaimer.propTypes = {
  email: PropTypes.string.isRequired
};

export default Disclaimer;
