import './popup.scss';
import React, { Component } from 'react';
import { Button, Modal } from 'react-bootstrap';
import PropTypes from 'prop-types';
import i18n from '../i18n';

class Popup extends Component {
  constructor(props, context) {
    super(props, context);

    this.handleClose = this.handleClose.bind(this);
    this.handleAccept = this.handleAccept.bind(this);
  }

  handleClose() {
    this.props.toggle();
  }

  handleAccept() {
    this.props.onAccept ? this.props.onAccept() : this.handleClose();
  }

  render() {
    return (
      <Modal
        show={this.props.showModal}
        onHide={this.handleClose}
        onEnter={() => (this.props.onEnter ? this.props.onEnter() : null)}
      >
        <Modal.Header closeButton>
          <Modal.Title>{this.props.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{this.props.children}</Modal.Body>
        <Modal.Footer>
          <Button onClick={this.handleClose} className="cancel-btn">
            {i18n.t('Cancel')}
          </Button>
          <Button
            onClick={this.handleAccept}
            className="ok-btn"
            disabled={this.props.disableOkBtn}
          >
            {this.props.confirmButtonTitle}
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

Popup.propTypes = {
  toggle: PropTypes.func,
  onAccept: PropTypes.func,
  onEnter: PropTypes.func,
  showModal: PropTypes.bool,
  title: PropTypes.string,
};

export default Popup;
