import React from 'react';
import cookies from 'react-cookies';
import './auth_popup.scss';
import i18n from '../i18n';

const AuthPopupFooter = () => {
  const lang =
    cookies.load('lang') === 'undefined' ? 'en' : cookies.load('lang');
  return (
    <div className="space footer-container">
      <p className="copyright">
        <span className="line-break">
          &copy;2020 Waitron.MENU. {i18n.t('All rights reserved')}
        </span>
        <a href={`/privacy/${lang}`} className="privacy-policy">
          {i18n.t('Privacy Policy')}
        </a>{' '}
        |
        <a href={`/terms_of_use/${lang}`} className="privacy-policy">
          {i18n.t('Terms of use')}
        </a>{' '}
        |
        <a href={`/public_agreement/${lang}`} className="privacy-policy">
          {i18n.t('Public Agreement')}
        </a>
      </p>
    </div>
  );
};
export default AuthPopupFooter;
