import './formatPreviewTabs.scss';
import React, { Component } from 'react';
import { Tabs, Tab, Grid, Row, Col } from 'react-bootstrap';
import PropTypes from 'prop-types';
import withFormats from '../withFormats';
import i18n from '../../../i18n';
import ImageFallback from '../../../image_fallback/ImageFallback';
import CustomSizeTab from './CustomSizeTab';

class FormatPreviewTabs extends Component {
  constructor(props) {
    super(props);
  }

  formatPreview(formatName) {
    return (
      <Col xs={4} key={formatName}>
        <div
          id={formatName}
          className={`format-wrapper ${
            this.props.selected === formatName ? 'selected' : ''
          }`}
          onClick={this.props.onFormatSelected}
        >
          <ImageFallback
            src={`/img/favicon/formats/icon-a4.svg`}
            className="format-preview"
          />
          <h4 className="format-name">{i18n.t(formatName)}</h4>
          <span className="text">
            {i18n.t(`${formatName} size`)}
            <br />
            <br />
          </span>
        </div>
      </Col>
    );
  }

  render() {
    const euFormats = this.props.formats.euFormats;
    const usFormats = this.props.formats.usFormats;

    return (
      <Tabs
        defaultActiveKey={1}
        id="format-tabs"
        onClick={e => this.props.setActiveTab(e.target.id.split('-').pop())}
      >
        <Tab eventKey={1} title={i18n.t('EU sizes')}>
          <Grid className="format-tab" fluid={true}>
            <Row>
              {euFormats.map(euFormat => this.formatPreview(euFormat.name))}
            </Row>
          </Grid>
        </Tab>
        <Tab eventKey={2} title={i18n.t('US sizes')}>
          <Grid className="format-tab">
            <Row>
              {usFormats.map(usFormat => this.formatPreview(usFormat.name))}
            </Row>
          </Grid>
        </Tab>
        {this.props.isAuth && (
          <Tab eventKey={3} title={i18n.t('Custom size')}>
            <Grid className="format-tab">
              <Row>
                <CustomSizeTab
                  customSizeChangeWidth={this.props.customSizeChangeWidth}
                  customSizeChangeHeight={this.props.customSizeChangeHeight}
                  customSizeChangeUnit={this.props.customSizeChangeUnit}
                  resize={this.props.resize}
                  activeMenu={this.props.activeMenu}
                />
              </Row>
            </Grid>
          </Tab>
        )}
      </Tabs>
    );
  }
}

FormatPreviewTabs.propTypes = {
  selected: PropTypes.string,
  onFormatSelected: PropTypes.func,
  customSizeChangeWidth: PropTypes.func,
  customSizeChangeHeight: PropTypes.func,
  customSizeChangeUnit: PropTypes.func,
  resize: PropTypes.bool,
  activeMenu: PropTypes.object,
  formats: PropTypes.shape({
    euFormats: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
      })
    ),
    usFormats: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
      })
    ),
  }),
};

export default withFormats(FormatPreviewTabs);
