import React from 'react';
import useCountDown from './useCountDown';
import './CountDown.scss';
import i18n from '../i18n';

const CountDown = ({ targetDate }) => {
  const DateTimeDisplay = ({ value, type }) => {
    const val = value < 10 ? `0${value}` : value;
    return (
      <div className="countdown">
        <div className="countdown-value-label">
          <p>{val}</p>
        </div>
        <div className="countdown-type-label">
          <span>{i18n.t(type)}</span>
        </div>
      </div>
    );
  };

  const [days, hours, minutes, seconds] = useCountDown(targetDate);
  return (
    <div className="show-counter">
      {days > 0 && <DateTimeDisplay type="Days" value={days} />}
      <DateTimeDisplay type="Hours" value={hours} />
      <DateTimeDisplay type="Minutes" value={minutes} />
      {days < 1 && <DateTimeDisplay type="Seconds" value={seconds} />}
    </div>
  );
};
export default CountDown;
