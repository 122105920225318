import './index.scss';
import axios from 'axios';
import React, { Component, lazy, Suspense } from 'react';
import PropTypes from 'prop-types';
import FormatsDropdownMenu from '../formats/FormatsDropdownMenu';
import NavigationBar from '../navigation_bar/NavigationBar.jsx';
import TemplatePreview from '../templates/template_preview/TemplatePreview';

class Templates extends Component {
  constructor(props) {
    super(props);

    this.getByFormat = this.getByFormat.bind(this);

    this.state = {
      templates: [],
      selectedFormatName: 'a4-0f',
    };
  }

  componentDidMount() {
    this.getByFormat();
  }

  getByFormat(format) {
    axios
      .get(
        `/new_flow/templates?format=${format || this.state.selectedFormatName}`
      )
      .then(({ data }) => {
        this.setState({
          templates: data.templates,
          selectedFormatName: format || this.state.selectedFormatName,
        });
      })
      .catch(err => console.error(err));
  }

  render() {
    const { templates } = this.state;
    return (
      <div className="container">
        <div className="title-container">
          <div className="nav-background">
            <div className="nav-block templates-nav-block">
              <NavigationBar />
            </div>
            <div className="formats-block">
              <FormatsDropdownMenu
                currentFormat={this.state.selectedFormatName}
                onFormatChange={this.getByFormat}
              />
            </div>
          </div>
        </div>
        <div className="templates-container">
          {templates.map(template => (
            <TemplatePreview
              template={template}
              user={this.props.user}
              key={template.id}
              handleOpen={this.props.handleOpen}
            />
          ))}
        </div>
      </div>
    );
  }
}

Templates.propTypes = {
  user: PropTypes.object,
};

export default Templates;
