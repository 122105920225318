import './formGroup.scss';
import React, { Component } from 'react';
import { FormGroup, ControlLabel, FormControl } from 'react-bootstrap';
import PropTypes from 'prop-types';

class InputFormGroup extends Component {
  constructor(props, context) {
    super(props, context);

    this.handleInputChange = this.handleInputChange.bind(this);
  }

  handleInputChange(e) {
    this.props.onInput(e.target.value);
  }

  render() {
    // todo: validate an input
    return (
      <FormGroup controlId={this.props.controlId} className="custom-form">
        <FormControl
          type={this.props.type || 'text'}
          value={this.props.value}
          onChange={this.handleInputChange}
          placeholder={this.props.placeholder || ' '}
          autoComplete="off"
          maxLength={this.props.maxLength}
          minLength={this.props.minLength}
          pattern={this.props.pattern}
          required
        />
        <ControlLabel>{this.props.caption}</ControlLabel>
        <div className={this.props.error ? 'underline-error' : 'underline'} />
      </FormGroup>
    );
  }
}

InputFormGroup.propTypes = {
  controlId: PropTypes.string,
  type: PropTypes.string,
  value: PropTypes.string,
  caption: PropTypes.string,
  onInput: PropTypes.func,
  error: PropTypes.bool,
};

export default InputFormGroup;
