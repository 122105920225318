import React, { useCallback, useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import axios from 'axios';

import i18n from '../i18n';
import InputFormGroup from '../form_group/InputFormGroup';
import * as notification from '../../common/notifications';

import "./styles.scss";

const BusinessDetailsPopUp = props => {
  const [categories, setCategories] = useState([]);
  const [checkedCategories, setCheckedCategories] = useState([]);
  const [businessName, setBusinessName] = useState('');
  const [businessWebsite, setBusinessWebsite] = useState('');
  const [businessAddress, setBusinessAddress] = useState('');

  const onSubmit = useCallback(
    async e => {
      e.preventDefault();

      try {
        await axios.post('/business', {
          categories: checkedCategories,
          website: businessName,
          name: businessWebsite,
          address: businessAddress,
        });

        window.location.reload();
      } catch (error) {
        notification.error(i18n.t('Something went wrong'));
      }
    },
    [businessName, businessWebsite, checkedCategories]
  );

  const handleCategoryChange = useCallback(
    value => {
      if (checkedCategories.includes(value)) {
        setCheckedCategories(checkedCategories.filter(c => c !== value));
      } else {
        setCheckedCategories([...checkedCategories, value]);
      }
    },
    [checkedCategories, setCheckedCategories]
  );

  const fetchCategories = useCallback(async () => {
    const { data } = await axios.get('/business-categories');
    setCategories(data);
  }, [setCategories]);

  useEffect(() => {
    if (!props.show) return;

    fetchCategories();
  }, [props.show, fetchCategories]);

  return (
    <Modal
      show={props.show}
      onHide={props.handleClose}
      className="bushiness-details-pop-up-wrapper"
      dialogClassName="bushiness-details-pop-up-wrapper"
    >
      <Modal.Header closeButton>
        <Modal.Title style={{ fontWeight: 'bold' }}>
          {i18n.t('Please add your business details')}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ padding: '2rem', paddingTop: '0px' }}>
        <form
          style={{ display: 'flex', flexDirection: 'column' }}
          onSubmit={onSubmit}
        >
          <div style={{ marginBottom: '1.5rem' }}>
            <InputFormGroup
              caption={i18n.t('Restaurant or business name')}
              controlId="business-name-input"
              onInput={setBusinessName}
              type="businessName"
            />
          </div>

          <div style={{ marginBottom: '1.5rem' }}>
            <InputFormGroup
              controlId="business-website-input"
              onInput={setBusinessWebsite}
              caption={i18n.t('Website or social media link')}
              type="businessWebsite"
            />
          </div>

          <div style={{ marginBottom: '3rem' }}>
            <InputFormGroup
              controlId="business-address-input"
              onInput={setBusinessAddress}
              caption={i18n.t('Address')}
              type="businessAddress"
            />
          </div>

          <div
            style={{ marginBottom: '1.5rem', fontWeight: 'bold' }}
            className="text-center"
          >
            {i18n.t('Type of establishment')}
          </div>

          <div className="row mb-4">
            {categories.map(category => (
              <div className="col-md-6" key={category.id}>
                <input
                  onChange={e => handleCategoryChange(e.target.value)}
                  className="custom-control-input"
                  value={category.id}
                  id={category.id}
                  type="checkbox"
                  style={{
                    marginRight: '10px',
                    cursor: 'pointer',
                  }}
                />
                <label
                  style={{ cursor: 'pointer', fontWeight: 'normal' }}
                  htmlFor={category.id}
                >
                  {category.name}
                </label>
              </div>
            ))}
          </div>

          <div className="row">
            <div className="col-md-12">
              <button className="btn btn-danger btn-block" type="submit">
                {i18n.t('Confirm')}
              </button>
            </div>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
};

export default BusinessDetailsPopUp;
